import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import ToastDialog from "../dialogs/ToastDialog";
import MainHeader from "../other-components/MainHeader";
import "../../MainStyle.css";
import {
  WEBSITE_NAME,
  API_ACCESS_URL,
  generateAuthToken,
} from "../modals/Constants";
import { setStorage, getStorage } from "../modals/Storage";
import LoadingDialog from "../dialogs/LoadingDialog";

import { useGoogleLogin } from "@react-oauth/google";

function Login() {
  const navigate = useNavigate();

  const [tempGoogleAuthData, setTempGoogleAuthData] = useState(null);

  const signInWithGoogle = useGoogleLogin({
    onSuccess: (codeResponse) => setTempGoogleAuthData(codeResponse),
    onError: (error) => console.log("Login Failed:", error),
  });

  const [isInputValCorrect, setInValCorrect] = useState(false);
  const [inMobileNum, setInMobileNum] = useState("");

  const [pageConst, setConstants] = useState({
    pageTitle: "Login",
    inMobileNum: "",
    inPassword: "",
    isLoadingShow: false,
    toastDialogShow: false,
    toastTimeAvail: 7,
    toastMessage: "",
    isSessionExist: true,
  });

  const [mainHeader, setMainHeader] = useState({
    showHeader: true,
    headerType: 2,
  });

  const updateLoadingStatus = (data) => {
    setConstants((previousState) => {
      return { ...previousState, isLoadingShow: data };
    });
  };

  const updateToastDialogState = (data, msg) => {
    setConstants((previousState) => {
      return { ...previousState, toastDialogShow: data };
    });

    setConstants((previousState) => {
      return { ...previousState, toastMessage: msg };
    });
  };

  const validateSigninWithMobile = () => {
    async function requestAPI(url, formData) {
      try {
        const res = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            route: "route-signin",
          },
          body: JSON.stringify(formData),
        });

        const data = await res.json();
        updateLoadingStatus(false);

        if (
          data.status_code == "account_created" ||
          data.status_code == "onboard_required"
        ) {
          setStorage("accountID", data.account_id);
          setStorage("accountSecret", data.account_secret);
          navigate("/onboard", { replace: true });
        } else if (data.status_code == "signin_successful") {
          setStorage("accountID", data.account_id);
          setStorage("accountSecret", data.account_secret);

          setStorage("accountEmail", data.account_email);
          setStorage("accountMobile", data.account_mobilenum);
          setStorage("accountFullName", data.account_fullname);
          navigate("/", { replace: true });
        } else if (data.status_code == "otp_required") {
          setStorage("accountID", data.account_id);
          setStorage("accountMobile", data.account_mobilenum);
          navigate("/otp-verification", { replace: true });
        } else if (data.status_code == "password_required") {
          setStorage("accountID", data.account_id);
          setStorage("accountMobile", data.account_mobilenum);
          navigate("/password-verification", { replace: true });
        }
      } catch (error) {
        updateLoadingStatus(false);
        updateToastDialogState(
          true,
          "There was a technical issue! Please try again!"
        );
      }
    }

    if (inMobileNum.length == 10) {
      updateLoadingStatus(true);
      const formData = {
        SIGNIN_EMAIL: "",
        SIGNIN_MOBILE: inMobileNum,
        SIGNIN_FULLNAME: "",
      };
      requestAPI(API_ACCESS_URL, formData);
    }
  };

  const validateSigninWithEmail = (data) => {
    async function requestAPI(url, formData) {
      try {
        const res = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            route: "route-signin",
          },
          body: JSON.stringify(formData),
        });

        const data = await res.json();
        updateLoadingStatus(false);

        if (data.status_code == "account_created") {
          setStorage("accountID", data.account_id);
          setStorage("accountSecret", data.account_secret);
          navigate("/onboard", { replace: true });
        } else if (data.status_code == "signin_successful") {
          setStorage("accountID", data.account_id);
          setStorage("accountSecret", data.account_secret);

          setStorage("accountEmail", data.account_email);
          setStorage("accountMobile", data.account_mobilenum);
          setStorage("accountFullName", data.account_fullname);
          navigate("/", { replace: true });
        }
      } catch (error) {
        updateLoadingStatus(false);
        updateToastDialogState(
          true,
          "There was a technical issue! Please try again!"
        );
      }
    }

    if (data) {
      let tempFullname = data.given_name + " " + data.family_name;
      const formData = {
        SIGNIN_EMAIL: data.email,
        SIGNIN_MOBILE: "",
        SIGNIN_FULLNAME: tempFullname,
      };
      requestAPI(API_ACCESS_URL, formData);
    }
  };

  const getEmailInfo = () => {
    async function requestAPI(url) {
      try {
        const res = await fetch(url, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${tempGoogleAuthData.access_token}`,
            Accept: "application/json",
          },
        });

        setTempGoogleAuthData(null);

        const data = await res.json();

        validateSigninWithEmail(data);
      } catch (error) {
        updateLoadingStatus(false);
      }
    }

    updateLoadingStatus(true);
    requestAPI(
      `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${tempGoogleAuthData.access_token}`
    );
  };

  useEffect(() => {
    if (getStorage("accountID") != "" && getStorage("accountSecret") != "") {
      navigate("/", { replace: true });
    } else {
      if (tempGoogleAuthData != null) {
        getEmailInfo();
      }
    }
  }, [tempGoogleAuthData]);

  return (
    <div
      className={`v-center app-bg ${
        pageConst.isSessionExist == true ? "" : ""
      }`}
    >
      <div className="h-100vh w-100">
        <ToastDialog
          intentData={pageConst}
          updateState={updateToastDialogState}
        />
        <LoadingDialog intentData={pageConst} />

        <div className="ps-rl h-100 ovf-scrl-y hide-sb scrn-anim-view">
          <MainHeader intentData={mainHeader} />

          <div className="split2ViewContainer h-100">
            <div className="split2ViewWrapper bg-welcomeview v-center flex-d-col pd-10">
              <h2 className="ft-sz-23">
                Welcome to the world of {WEBSITE_NAME}
              </h2>
              <img
                className="w-80 mg-t-20"
                src={require("../media/illustrations/1.webp")}
              />
            </div>

            <div className="split2ViewWrapper v-center flex-d-col">
              <div className="split2ViewWrapperContents pd-30-5">
                <h1 className="ft-sz-20">Log In /Sign Up</h1>
                <p className="ft-sz-14 mg-t-10">
                  for Latest trends, exciting offers and everything{" "}
                  {WEBSITE_NAME}
                </p>

                <div className="row-view pd-10 bg-white br-10 br-a-grey mg-t-30">
                  <div className="row-view pd-5-10 br-5 bg-l-grey">
                    <img
                      className="h-w-22"
                      src={require("../media/icons/india_flag_icon.png")}
                    />
                    <label className="ft-wgt-600 mg-l-10">+91</label>
                  </div>

                  <input
                    type="number"
                    className="w-100 inp-box ft-sz-16 inp-ph-color-l-white bg-white mg-l-10"
                    placeholder="Enter Mobile Number"
                    autoComplete="off"
                    onChange={(e) => setInMobileNum(e.target.value)}
                  ></input>
                </div>

                <div
                  className={`h-50-p v-center ft-sz-20 br-5 cl-white mg-t-20 ${
                    inMobileNum.length == 10 ? "bg-primary" : "bg-disable-view"
                  }`}
                  onClick={() => validateSigninWithMobile()}
                >
                  <span
                    className={`ft-wgt-600 ${
                      pageConst.isLoadingShow == true ? "hide-v" : ""
                    }`}
                  >
                    Login
                  </span>
                </div>

                <p className="w-100 txt-a-center ft-wgt-600 mg-t-20 bg-white">
                  OR
                </p>

                <div
                  className={`w-100 h-50-p v-center ft-sz-16 br-10 br-a-grey mg-t-20`}
                  onClick={() => signInWithGoogle()}
                >
                  <div
                    className={`h-20-p w-20-p lodr-v ${
                      pageConst.isLoadingShow == false ? "hide-v" : ""
                    }`}
                  ></div>

                  <div
                    className={`v-center ${
                      pageConst.isLoadingShow == true ? "hide-v" : ""
                    }`}
                  >
                    <img
                      className="h-w-22"
                      src={require("../media/icons/google_icon.png")}
                    />
                    <span className="ft-wgt-600 mg-l-10">
                      Continue with Google
                    </span>
                  </div>
                </div>

                <div className="pd-10 txt-a-center ft-sz-13 mg-t-20 mg-b-30">
                  By creating an account or logging in, you agree with{" "}
                  {WEBSITE_NAME}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
