import React,{useState, useEffect} from "react";
import { Link,useNavigate } from 'react-router-dom';
import { getStorage,deleteStorage } from '../modals/Storage';
import MainHeader from "../other-components/MainHeader";
import { API_ACCESS_URL } from "../modals/Constants";
import LoadingDialog from "../dialogs/LoadingDialog";

function AllAddresses() {
  const navigate = useNavigate();

  const [isDataLoaded, setIsDataLoaded] = useState(false);

  const [pageConst, setConstants] = useState({
    showHeadSearch: true,
    toastMessage: "",
    toastDialogShow: false,
    isShowFooter: true,
    isLoadingShow: false,
    addressList: [],
  });

  const [mainHeader, setMainHeader] = useState({
    showHeader: true,
    headerType: 2
  });

  const updateLoadingStatus = (data) => {
    setConstants(previousState => {
        return { ...previousState, isLoadingShow: data }
    });
  }

  const updateUIItems = (data) => {
    let tempData = [];

    for (let i = 0; i < data.length; i++) {
      tempData.push(
      <div className="col-view pd-20-10 br-5 br-a-l-grey">
        <span className="ft-sz-16 ft-wgt-600">{data[i]['address_fullname']}</span>
        <span className="ft-sz-13 mg-t-5">{data[i]['address_state']+','+data[i]['address_area']}</span>
        
        <span className="ft-sz-13 mg-t-15">{data[i]['address_city']+','+data[i]['address_pincode']}</span>
        <span className="ft-sz-13 mg-t-5">Contact Number: {data[i]['address_mobile']}</span>

        <div className="row-view mg-t-20">
          <Link className="txt-deco-n cl-blue pd-5 ft-sz-16 cursor-pointer" to={"/edit-address/"+data[i]['address_id']}>Edit</Link>
          <div className="pd-5 cl-red ft-sz-16 mg-l-15 cursor-pointer" onClick={() => removeAddress(data[i]['address_id'])}>Remove</div>
        </div>
      </div>
      )
    };

    setConstants((previousState) => {
      return { ...previousState, addressList: tempData };
    });
  };
  
  const removeAddress = (addressId) =>{
    async function requestAPI(url,formData) {
      try {
        const res = await fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'route': 'route-remove-address',
            // 'AuthToken': generateAuthToken(pageConst.inMobileNum,ip),
          },
          body: JSON.stringify(formData),
        });
        
        const data = await res.json();
        updateLoadingStatus(false);

        if(data.status_code="success"){
          setIsDataLoaded(false);
          loadAllAddresses();
        }
      } catch (error) {
        updateLoadingStatus(false);
      }
    }

    if((addressId!="")){
      updateLoadingStatus(true);
      const formData = { ACCOUNT_ID: getStorage("accountID"), AUTH_SECRET: getStorage("accountSecret"), ADDRESS_ID: addressId};
      requestAPI(API_ACCESS_URL, formData);
    }
  }

  function loadAllAddresses(){
    async function requestAPI(url,formData) {
      try {
        const res = await fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'route': 'route-addresses',
          },
          body: JSON.stringify(formData),
        });
        
        const data = await res.json();
        updateLoadingStatus(false);

        if(data.status_code=="success"){
          updateUIItems(data.data);
        } else if (data.account_status == "inactive") {
          navigate("/account-update-required", { replace: true });
        } else if (data.account_status == "unauthorized") {
          if (deleteStorage()) {
            navigate("/signin", { replace: true });
          }
        }
      } catch (error) {
        updateLoadingStatus(false);
      }
    };

    if((!isDataLoaded)){
      updateLoadingStatus(true);
      setIsDataLoaded(true);
      const formData = { ACCOUNT_ID: getStorage("accountID"), AUTH_SECRET: getStorage("accountSecret") };
      requestAPI(API_ACCESS_URL, formData);
    }
  }

  useEffect(() => {
    if(getStorage("accountID")=='' || getStorage("accountSecret")==''){
      navigate('/signin', { replace: true });
    }else{
      loadAllAddresses();
    }
  }, []);

  return (
    <div className="v-center app-bg">
      <div className="h-100vh w-100 ovf-scrl-y">
        <LoadingDialog intentData={pageConst}/>

        <div className='w-100 col-view a-center'>

          <MainHeader intentData={mainHeader}/>

          <div className="col-view min-h-100vh resp-width margin-0-auto">
            <div className="accountOptionsContainer">
              <div className="accountOptionsContainerWrapper">

                <Link className="txt-deco-n min-h-180 v-center flex-d-col cl-black pd-20-10 bg-l-primary br-5 br-a-primary" to={"/add-address"}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 25 24" stroke="none" style={{'height': '24px', 'width': '25px'}}><path stroke="#207BB4" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M4.25 12h16.5M12.5 3.75v16.5"></path></svg>
                  <span className="mg-t-30">Add New</span>
                  <span className="txt-a-center ft-sz-12 mg-t-5">Add, Edit address</span>

                </Link>

                {pageConst.addressList}

              </div>
             
            </div>
          </div>

        </div>

      </div>
    </div>
  );
}

export default AllAddresses;
