import React, { useEffect, useState, useRef } from 'react';
import { Link,useNavigate  } from 'react-router-dom';
import '../../MainStyle.css';
import ToastDialog from '../dialogs/ToastDialog';
import { API_ACCESS_URL,HOME_REDIRECT_URL,redirectTo } from '../modals/Constants';
import LoadingDialog from "../dialogs/LoadingDialog";
import MainHeader from "../other-components/MainHeader";
import { getStorage, setStorage } from '../modals/Storage';

function OnBoard(){
  
    const Ref = useRef(null);
    const navigate = useNavigate();

    const [isDataLoaded, setIsDataLoaded] = useState(false);
    const [isInputValCorrect, setInValCorrect] = useState(false);

    const [inEmailId, setInEmailId] = useState("");
    const [inMobileNum, setInMobileNum] = useState("");
    const [inFullName, setInFullName] = useState("");
    const [inPassword, setInPassword] = useState("");

    const [pageConst, setConstants] = useState({
        pageTitle: "Register",
        isLoadingShow: false,
        toastDialogShow: false,
        toastTimeAvail: 7,
        toastMessage: "",
    });

    const [mainHeader, setMainHeader] = useState({
      showHeader: true,
      headerType: 2
    });

    const updateLoadingStatus = (data) => {
        setConstants(previousState => {
            return { ...previousState, isLoadingShow: data }
        });
    }

    const updateOTPSendingStatus = (data) =>{
      setConstants(previousState => {
        return { ...previousState, isOTPSending: data }
      });
    }

    const updateToastDialogState = (data,msg) => {
      setConstants(previousState => {
        return { ...previousState, toastDialogShow: data }
      });

      setConstants(previousState => {
        return { ...previousState, toastMessage: msg }
      });
    }

    const saveAccountDetails = () => {
      const requestAPI = async (url,formData) => {
        try {
          const res = await fetch(url, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'route': 'route-update-account',
              // 'AuthToken': generateAuthToken(pageConst.inMobileNum,ip),
            },
            body: JSON.stringify(formData),
          });

          const data = await res.json();
          updateLoadingStatus(false);

          if(data.status_code="success"){
            setStorage("accountEmail", inEmailId);
            setStorage("accountMobile", inMobileNum);
            setStorage("accountFullName", inFullName);
            
            navigate('/', { replace: true });
          }else{
            updateToastDialogState(true,"There was a technical issue! Please try again!");
          }
        } catch (error) {
          updateLoadingStatus(false);
          updateToastDialogState(true,"There was a technical issue! Please try again!");
        }
      };

      if(inEmailId!="" && inMobileNum!="" && inFullName!="" && inPassword){
        updateLoadingStatus(true);
        const formData = { ACCOUNT_ID: getStorage("accountID"), ACCOUNT_EMAIL: inEmailId, ACCOUNT_MOBILE: inMobileNum, ACCOUNT_FULLNAME: inFullName, ACCOUNT_PASSWORD: inPassword, AUTH_SECRET: getStorage("accountSecret")};
        requestAPI(API_ACCESS_URL,formData);
      }
    }


    const getAccountInfo = () =>{
      const requestAPI = async (url,formData) => {
        try {
          const res = await fetch(url, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'route': 'route-account-info',
              // 'AuthToken': generateAuthToken(pageConst.inMobileNum,ip),
            },
            body: JSON.stringify(formData),
          });
          
          const data = await res.json();
          updateLoadingStatus(false);

          setInFullName(data['data'][0]['account_fullname']);
          setInEmailId(data['data'][0]['account_emailid']);
          setInMobileNum(data['data'][0]['account_mobile_num']);
          setIsDataLoaded(true);
        } catch (error) {
          updateLoadingStatus(false);
          updateToastDialogState(true,"There was a technical issue! Please try again!");
        }
      };

      if(!isDataLoaded && getStorage("accountID")!='' && getStorage("accountSecret")!=''){
        updateLoadingStatus(true);
        const formData = { ACCOUNT_ID: getStorage("accountID"), AUTH_SECRET: getStorage("accountSecret")};
        requestAPI(API_ACCESS_URL,formData);
      }
    }

    useEffect(() => {
      if(getStorage("accountID")=='' || getStorage("accountSecret")==''){
        navigate('/signin', { replace: true });
      }else{
        getAccountInfo();
      }
    }, [inEmailId,inMobileNum]);

    return (
      <div className="v-center app-bg">
        <div className="h-100vh w-100 ovf-scrl-y">
          <ToastDialog intentData={pageConst} updateState={updateToastDialogState} />
          <LoadingDialog intentData={pageConst}/>

          <div className="w-100 col-view">
            <MainHeader intentData={mainHeader}/>

            <div className="col-view resp-width pd-15 margin-0-auto">

              <div className='col-view inp-container mg-t-15'>
                <span className='ft-sz-13'>Full Name</span>
                <input type="text" className='inp-box inp-b-bottom ft-sz-18 mg-t-10' autoComplete="new-password" value={inFullName} onChange={e => setInFullName(e.target.value)}></input>
              </div>

              <div className='col-view inp-container mg-t-15'>
                <span className='ft-sz-13'>Email Id</span>
                <input type="text" className='inp-box inp-b-bottom ft-sz-18 mg-t-10' autoComplete="new-password" value={inEmailId} onChange={e => setInEmailId(e.target.value)}></input>
              </div>

              <div className='col-view inp-container mg-t-15'>
                <span className='ft-sz-13'>Mobile</span>
                <input type="number" className='inp-box inp-b-bottom ft-sz-18 mg-t-10' autoComplete="new-password" value={inMobileNum} onChange={e => setInMobileNum(e.target.value)}></input>
              </div>

              <div className='col-view inp-container mg-t-15'>
                <span className='ft-sz-13'>Password</span>
                <input type="password" className='inp-box inp-b-bottom ft-sz-18 mg-t-10' autoComplete="new-password" onChange={e => setInPassword(e.target.value)}></input>
              </div>

              <div className={`w-100 mg-t-20 h-50-p ft-sz-20 v-center br-5 cl-white bg-primary`} onClick={() => saveAccountDetails()}>
                <span>Save</span>
              </div>
            </div>

          </div>
        </div>
      </div>
    );
}

export default OnBoard;