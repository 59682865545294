import React, { useEffect, useState, useRef } from 'react';
import { Link,useNavigate  } from 'react-router-dom';
import '../../MainStyle.css';
import ToastDialog from '../dialogs/ToastDialog';
import { API_ACCESS_URL } from '../modals/Constants';
import LoadingDialog from "../dialogs/LoadingDialog";
import MainHeader from "../other-components/MainHeader";
import { getStorage } from '../modals/Storage';

function AddAddress(){
    const navigate = useNavigate();
    
    const [inMobileNum, setInMobileNum] = useState("");
    const [inFullName, setInFullName] = useState("");
    const [inPinCode, setPinCode] = useState("");
    const [inCity, setCity] = useState("");
    const [inState, setState] = useState("");
    const [inAreaLocality, setAreaLocality] = useState("");

    const [pageConst, setConstants] = useState({
        pageTitle: "Register",
        isLoadingShow: false,
        toastDialogShow: false,
        toastMessage: "",
    });

    const [mainHeader, setMainHeader] = useState({
      showHeader: true,
      headerType: 2
    });

    const updateLoadingStatus = (data) => {
        setConstants(previousState => {
            return { ...previousState, isLoadingShow: data }
        });
    }

    const updateToastDialogState = (data,msg) => {
      setConstants(previousState => {
        return { ...previousState, toastDialogShow: data }
      });

      setConstants(previousState => {
        return { ...previousState, toastMessage: msg }
      });
    }

    const saveAccountDetails = () => {
      const requestAPI = async (url,formData) => {
        try {
          const res = await fetch(url, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'route': 'route-add-address',
            },
            body: JSON.stringify(formData),
          });

          const data = await res.json();
          updateLoadingStatus(false);

          if(data.status_code="success"){
            navigate('/addresses', { replace: true });
          }else{
            updateToastDialogState(true,"There was a technical issue! Please try again!");
          }
        } catch (error) {
          updateLoadingStatus(false);
          updateToastDialogState(true,"There was a technical issue! Please try again!");
        }
      };

      if(inMobileNum!="" && inFullName!="" && inPinCode && inCity!="" && inState!="" && inAreaLocality!=""){
        updateLoadingStatus(true);
        const formData = { ACCOUNT_ID: getStorage("accountID"), ADDRESS_FULLNAME: inFullName, ADDRESS_MOBILE: inMobileNum, ADDRESS_PINCODE: inPinCode, ADDRESS_CITY: inCity, ADDRESS_STATE: inState, ADDRESS_AREA: inAreaLocality, AUTH_SECRET: getStorage("accountSecret")};
        requestAPI(API_ACCESS_URL,formData);
      }
    }

    useEffect(() => {
      if(getStorage("accountID")=='' || getStorage("accountSecret")==''){
        navigate('/signin', { replace: true });
      }
    }, [inFullName,inMobileNum,inPinCode,inCity,inState,inAreaLocality]);

    return (
      <div className="v-center app-bg">
        <div className="h-100vh w-100 ovf-scrl-y">
          <ToastDialog intentData={pageConst} updateState={updateToastDialogState} />
          <LoadingDialog intentData={pageConst}/>

          <div className="w-100 col-view">
            <MainHeader intentData={mainHeader}/>

            <div className="col-view resp-width pd-15 mg-b-25 margin-0-auto">

              <div className='col-view inp-container mg-t-15'>
                <span className='ft-sz-13'>Full Name*</span>
                <input type="text" className='inp-box inp-b-bottom ft-sz-18 mg-t-10' autoComplete="new-password" value={inFullName} onChange={e => setInFullName(e.target.value)}></input>
              </div>

              <div className='col-view inp-container mg-t-15'>
                <span className='ft-sz-13'>Mobile*</span>
                <div className='row-view w-100'>
                  <span className='mg-r-5'>+91</span>
                  <input type="number" className='w-100 inp-box inp-b-bottom ft-sz-18 mg-t-10' autoComplete="new-password" value={inMobileNum} onChange={e => setInMobileNum(e.target.value)}></input>
                </div>
              </div>

              <div className='col-view inp-container mg-t-15'>
                <span className='ft-sz-13'>PIN Code/ZIP Code*</span>
                <input type="number" className='inp-box inp-b-bottom ft-sz-18 mg-t-10' autoComplete="new-password" value={inPinCode} onChange={e => setPinCode(e.target.value)}></input>
              </div>

              <div className='col-view inp-container mg-t-15'>
                <span className='ft-sz-13'>City*</span>
                <input type="text" className='inp-box inp-b-bottom ft-sz-18 mg-t-10' value={inCity} onChange={e => setCity(e.target.value)}></input>
              </div>

              <div className='col-view inp-container mg-t-15'>
                <span className='ft-sz-13'>State*</span>
                <input type="text" className='inp-box inp-b-bottom ft-sz-18 mg-t-10' value={inState} onChange={e => setState(e.target.value)}></input>
              </div>

              <div className='col-view inp-container mg-t-15'>
                <span className='ft-sz-13'>Area/Locality*</span>
                <input type="text" className='inp-box inp-b-bottom ft-sz-18 mg-t-10' value={inAreaLocality} onChange={e => setAreaLocality(e.target.value)}></input>
              </div>

              <div className={`w-100 mg-t-20 h-50-p ft-sz-20 v-center br-5 cl-white bg-primary`} onClick={() => saveAccountDetails()}>
                <span>Save Address</span>
              </div>
            </div>

          </div>
        </div>
      </div>
    );
}

export default AddAddress;