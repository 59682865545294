import React, { useState, useRef, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import ToastDialog from "../dialogs/ToastDialog";
import MainHeader from "../other-components/MainHeader";
import { API_ACCESS_URL } from "../modals/Constants";
import Footer from "../other-components/Footer";
import { getStorage, setStorage } from "../modals/Storage";
import ContainerV3Modal from "../other-components/ContainerV3Modal";
import ContainerV1Modal from "../other-components/ContainerV1Modal";
import ContainerV2Modal from "../other-components/ContainerV2Modal";
import LoadingDialog from "../dialogs/LoadingDialog";
import ContainerV4Modal from "../containerModals/ContainerV4Modal";
import ContainerV5Modal from "../containerModals/ContainerV5Modal";
import ContainerV6Modal from "../containerModals/ContainerV6Modal";
// import Scroll from '../other-components/SmoothScroll';

function Home() {
  const navigate = useNavigate();
  const [slideShowIndex, setSlideShowIndex] = useState(0);
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [productsList, updateProductsList] = useState(null);
  const delay = 2500;

  // const timeoutRef = useRef(null);

  // function resetTimeout() {
  //   if (timeoutRef.current) {
  //     clearTimeout(timeoutRef.current);
  //   }
  // }

  const [pageConst, setConstants] = useState({
    showHeadSearch: true,
    toastMessage: "",
    toastDialogShow: false,
    isShowFooter: true,
    isLoadingShow: false,
    sliderImages: [],
    recordList: [],
    productsList: [],
    homeUI: [],
  });

  const [mainHeader, setMainHeader] = useState({
    showHeader: true,
    headerType: 1,
  });

  const updateLoadingStatus = (data) => {
    setConstants((previousState) => {
      return { ...previousState, isLoadingShow: data };
    });
  };

  const setSliderView = (data) => {
    if (data != "") {
      let tempData = [];

      for (let i = 0; i < data.length; i++) {
        tempData.push(data[i]["slider_img"]);
      }

      setConstants((previousState) => {
        return { ...previousState, sliderImages: tempData };
      });
    }
  };

  const updateToastDialogState = (data, msg) => {
    setConstants((previousState) => {
      return { ...previousState, toastDialogShow: data };
    });

    setConstants((previousState) => {
      return { ...previousState, toastMessage: msg };
    });
  };

  const updateUI = (data) => {
    let tempData = [];

    for (let i = 0; i < data.length; i++) {
      tempData.push(
        <div className={`w-100 ${data[i]["container_class"]}`}>
          <span className="ft-wgt-500">{data[i]["container_title"]}</span>

          <div
            className={`w-100 ${data[i]["container_class"]}-holder pd-10 mg-t-10`}
          >
            {data[i]["container_class"] == "container-v-1" ? (
              <ContainerV1Modal dataList={data[i]["items"]} />
            ) : data[i]["container_class"] == "container-v-2" ? (
              <ContainerV2Modal dataList={data[i]["items"]} />
            ) : data[i]["container_class"] == "container-v-3" ? (
              <ContainerV3Modal dataList={data[i]["items"]} />
            ) : data[i]["container_class"] == "container-v-4" ? (
              <ContainerV4Modal dataList={data[i]["items"]} />
            ) : data[i]["container_class"] == "container-v-5" ? (
              <ContainerV5Modal dataList={data[i]["items"]} />
            ) : data[i]["container_class"] == "container-v-6" ? (
              <ContainerV6Modal dataList={data[i]["items"]} />
            ) : (
              ""
            )}
          </div>
        </div>
      );
    }

    setConstants((previousState) => {
      return { ...previousState, homeUI: tempData };
    });
  };

  const updateCategoryArr = (data) => {
    setStorage("categoryArr", JSON.stringify(data));
  };

  function loadHomeScreenData() {
    async function requestAPI(url, formData) {
      try {
        const res = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            route: "route-home-screen",
            // 'AuthToken': generateAuthToken(pageConst.inMobileNum,ip),
          },
          body: JSON.stringify(formData),
        });

        const data = await res.json();
        updateLoadingStatus(false);

        if (data.status_code == "success") {
          setStorage("paymentURL", data.payment_url);
          setStorage("helpSuportURL", data.help_support_url);
          setStorage("privacyPolicyURL", data.privacy_policy_url);
          setStorage("refundPolicyURL", data.refund_policy_url);
          setStorage("accountBagItems", data.total_bag_items);
          updateUI(data.containersData);
          updateProductsList(data.productData);
          updateCategoryArr(data.categoryData);

          setIsDataLoaded(true);
          updateLoadingStatus(false);
        }
      } catch (error) {
        updateLoadingStatus(false);
      }
    }

    if (!isDataLoaded) {
      updateLoadingStatus(true);
      const formData = { ACCOUNT_ID: getStorage("accountID") };
      requestAPI(API_ACCESS_URL, formData);
    }
  }

  const handleScrollBar = (e) => {
    const target = e.target;
  };

  useEffect(() => {
    loadHomeScreenData();
  }, [pageConst.sliderImages, slideShowIndex]);

  return (
    <div className="v-center app-bg">
      <div
        className="h-100vh w-100 ovf-scrl-y"
        onScroll={(e) => handleScrollBar(e)}
      >
        <ToastDialog
          intentData={pageConst}
          updateState={updateToastDialogState}
        />
        <LoadingDialog intentData={pageConst} />

        <div className="w-100 col-view a-center">
          <MainHeader intentData={mainHeader} />

          <div className="col-view min-h-100vh resp-width margin-0-auto">
            {pageConst.homeUI}
          </div>
        </div>

        <Footer intentData={pageConst} />
      </div>
    </div>
  );
}

export default Home;
