import React, {useEffect,useState} from 'react'
import { Link } from 'react-router-dom';
import '../../MainStyle.css';
import { Player } from '@lottiefiles/react-lottie-player';

const LoadingDialog = ({intentData}) => {

    return (
        <div className={`pos-fx pos-top-0 pos-rgt-0 w-100 h-100vh v-center z-i-1000 bg-l-black ${intentData.isLoadingShow ? '' : 'hide-v'}`}>
            <Player src={require("../media/animations/loading_anim.json")} className="player" loop autoplay />
        </div>
    );
};

export default LoadingDialog;