import React, { useState, useRef, useEffect } from "react";
import { Link, useNavigate, useParams  } from "react-router-dom";
import ToastDialog from "../dialogs/ToastDialog";
import { WEBSITE_URL,API_ACCESS_URL, openNewPage} from "../modals/Constants";
import MainHeader from "../other-components/MainHeader";
import { getStorage } from "../modals/Storage";
import LoadingDialog from "../dialogs/LoadingDialog";

function Checkout() {
  const navigate = useNavigate();
  const [paymentMethod, setPaymentMethod] = useState("");
  const [paymentCod, setPaymentCod] = useState("false");
  const [paymentOnline, setPaymentOnline] = useState("false");


  const [pageConst, setConstants] = useState({
    showHeadSearch: true,
    totalMRP: 0,
    totalDiscount: 0,
    totalDeliveryFee: 0,
    subTotalCost: 0,
    totalBagItems: -1,
    toastMessage: "",
    toastDialogShow: false,
    slideShowImage: "",
    isLoadingShow: false,
    isShowFooter: true,
    imageList: [],
    productList: [],
  });

  const [mainHeader, setMainHeader] = useState({
    showHeader: true,
    headerType: 2
  });


  const updateAddressArray = (data) =>{

  }


  const updateLoadingStatus = (data) => {
    setConstants(previousState => {
        return { ...previousState, isLoadingShow: data }
    });
  }

  const updateToastDialogState = (data, msg) => {
    setConstants((previousState) => {
      return { ...previousState, toastDialogShow: data };
    });

    setConstants((previousState) => {
      return { ...previousState, toastMessage: msg };
    });
  };


  function loadMyBag(forcedLoad){
    async function requestAPI(url,formData) {
      try {
        const res = await fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'route': 'route-bag-products',
            // 'AuthToken': generateAuthToken(pageConst.inMobileNum,ip),
          },
          body: JSON.stringify(formData),
        });
        
        const data = await res.json();
        updateLoadingStatus(false);

        if(data.status_code="success"){
          setPaymentCod(data.payment_cod);
          setPaymentOnline(data.payment_online);

          if(data.payment_cod=='true'){
            setPaymentMethod('cod');
          }else if(data.payment_online=='true'){
            setPaymentMethod('online');
          }

          setConstants((previousState) => {
            return { ...previousState, totalMRP: data.total_mrp };
          });

          setConstants((previousState) => {
            return { ...previousState, totalDiscount: data.total_discount };
          });

          setConstants((previousState) => {
            return { ...previousState, totalDeliveryFee: data.total_delivery_fee };
          });

          setConstants((previousState) => {
            return { ...previousState, subTotalCost: data.subtotal_cost };
          });

          if(data.data.length > 0){
            setConstants((previousState) => {
              return { ...previousState, totalBagItems: data.data.length };
            });
          }else{
            setConstants((previousState) => {
              return { ...previousState, totalBagItems: 0 };
            });
          }

          updateAddressArray(data.addressArr);
          
        }
      } catch (error) {
        updateLoadingStatus(false);
      }
    };

    if((!pageConst.dataLoaded || forcedLoad)){
      updateLoadingStatus(true);

      setConstants(previousState => {
        return { ...previousState, dataLoaded: true }
      });
      const formData = { ACCOUNT_ID: getStorage("accountID"), AUTH_SECRET: getStorage("accountSecret"), BAG_PRODUCTS: ""};
      requestAPI(API_ACCESS_URL, formData);
    }
  }

  const proceedConfirmOrders = () =>{
    async function requestAPI(url,formData) {
      try {
        const res = await fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'route': 'route-confirm-order',
            // 'AuthToken': generateAuthToken(pageConst.inMobileNum,ip),
          },
          body: JSON.stringify(formData),
        });
        
        const data = await res.json();
        updateLoadingStatus(false);

        if(data.status_code="success"){
          navigate('/order-confirmed', { replace: true }); 
        }
      } catch (error) {
        updateLoadingStatus(false);
      }
    };

    if(paymentOnline=='true' && paymentMethod!="cod"){
      openNewPage(getStorage("paymentURL")+"?am="+pageConst.subTotalCost+"&crudentials="+getStorage("accountID"));
    }else if(paymentCod=='true'){
      updateLoadingStatus(true);
      const formData = { ACCOUNT_ID: getStorage("accountID"), AUTH_SECRET: getStorage("accountSecret"), PAYMENT_MODE: paymentMethod };
      requestAPI(API_ACCESS_URL, formData);
    }
    
  }

  useEffect(() => {
    loadMyBag(false);
  }, []);

  return (
    <div className="v-center app-bg">
      <div className="h-100vh w-100 ovf-scrl-y">
        <ToastDialog intentData={pageConst} updateState={updateToastDialogState} />
        <LoadingDialog intentData={pageConst}/>

        <div className="w-100 col-view">
          <MainHeader intentData={mainHeader}/>

          <div className="col-view min-h-100vh resp-width margin-0-auto">
            
            <div className="pd-0-15 col-view mg-t-15 pd-b-100">
              <h3>My Bag ({pageConst.totalBagItems} item)</h3>

              <div className="cartItemsContainer w-100 mg-t-55">

                <div className="cartItemsWrapper col-view">

                  <div className="row-view sb-view pd-20 cursor-pointer" onClick={() => paymentCod=='true' ? setPaymentMethod('cod') : ''}>
                    <div className="row-view">
                      <img className="h-w-26" src="https://images.bewakoof.com/web/cod-icon-1645705427.png" />
                      <div className="col-view mg-l-15">
                        <span className="ft-sz-16 ft-wgt-600">Cash On Delivery</span>
                        <span className="ft-sz-12 mg-t-5">Pay when you receive orders.</span>
                         <span className={`w-fit-content ft-sz-12 pd-5-10 mg-t-10 br-5 bg-l-grey ${paymentCod!='true' ? '' : 'hide-v'}`}>Not Avalable</span>
                      </div>
                    </div>

                    {paymentMethod=='cod' ? <img className="h-w-26" src={require("../media/icons/check_icon_selected.png")} alt="icon" /> : <img className="h-w-26 opac-01" src={require("../media/icons/check_icon_unselected.png")} alt="icon" />}
                  </div>

                  <div className="row-view sb-view pd-20 cursor-pointer" onClick={() => paymentOnline=='true' ? setPaymentMethod('online') : ''}>
                    <div className="row-view">
                      <img className="h-w-26" src="https://images.bewakoof.com/web/upi-icon-1645705429.png" />
                      <div className="col-view mg-l-15">
                        <span className="ft-sz-16 ft-wgt-600">Online Payment</span>
                        <span className="ft-sz-12 mg-t-5">Pay via UPI & QR</span>
                        <span className={`w-fit-content ft-sz-12 pd-5-10 mg-t-10 br-5 bg-l-grey ${paymentOnline!='true' ? '' : 'hide-v'}`}>Not Avalable</span>
                      </div>
                    </div>

                    {paymentMethod=='online' ? <img className="h-w-26" src={require("../media/icons/check_icon_selected.png")} alt="icon" /> : <img className="h-w-26 opac-01" src={require("../media/icons/check_icon_unselected.png")} alt="icon" />}
                  </div>
                </div>

                <div className="cartSummaryWrapper h-fit-content col-view mg-l-15 br-5">
                  
                  <div className="col-view">
                    <span className="ft-sz-14 ft-wgt-600 pd-10">Payment Details</span>

                    <div className="row-view sb-view ft-sz-14 pd-10">
                      <span>Total MRP (Inc. of taxes)</span>
                      <span>₹{pageConst.totalMRP}</span>
                    </div>

                    <div className="row-view sb-view ft-sz-14 pd-10">
                      <span>Delivery Fee</span>
                      <span>{pageConst.totalDeliveryFee > 0 ? '₹'+pageConst.totalDeliveryFee : 'Free'}</span>
                    </div>

                    <div className="row-view sb-view ft-sz-14 pd-10">
                      <span>Bag Discount</span>
                      <span>-₹{pageConst.totalDiscount}</span>
                    </div>

                    <div className="line-hv-grey mg-t-15"></div>

                    <div className="row-view sb-view ft-sz-14 pd-15">
                      <span className="ft-sz-17 ft-wgt-600">Sub Total</span>
                      <span className="ft-sz-17 ft-wgt-600">₹{pageConst.subTotalCost}</span>
                    </div>
                  </div>

                  <div className="pd-15" onClick={() => proceedConfirmOrders()}>
                    <div className="v-center pd-10-15 ft-wgt-600 br-5 bg-primary">Proceed</div>
                  </div>
                  

                </div>
              </div>
            </div>
            
          </div>

        </div>
        
      </div>
    </div>
  );
}

export default Checkout;