export function setStorage(sname,svalue) {
    return localStorage.setItem(sname, svalue);
 }
 
 export function getStorage(sname) {
    if (localStorage.hasOwnProperty(sname)) {
        return localStorage.getItem(sname);
    }else{
        return '';
    }
    
 }
 
 export function deleteStorage(){
     localStorage.clear();
     return true;
 }