import React, { useState, useRef, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import ToastDialog from "../dialogs/ToastDialog";
import { API_ACCESS_URL } from "../modals/Constants";
import Footer from "../other-components/Footer";
import MainHeader from "../other-components/MainHeader";
import { deleteStorage, getStorage, setStorage } from "../modals/Storage";
import LoadingDialog from "../dialogs/LoadingDialog";
import ImageLoader from "../other-components/ImageLoader";
import DialogV2 from "../dialogs/DialogV2";

import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/react-splide/css";

import DialogZoomImage from "../dialogs/DialogZoomImage/dialog";

function ViewProduct() {
  const navigate = useNavigate();
  let { urlParam } = useParams();
  const [slideShowIndex, setSlideShowIndex] = useState(0);
  const [selectedProductSize, setProductSize] = useState("");

  const [isShowAvilableOffers, setShowAvilableOffers] = useState(false);
  const [isShowDescription, setShowDescription] = useState(false);
  const [isShowReturnPolicy, setShowReturnPolicy] = useState(false);

  const [isDataLoaded, setIsDataLoaded] = useState("");
  const delay = 5000;

  const timeoutRef = useRef(null);

  function resetTimeout() {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  }

  const [pageConst, setConstants] = useState({
    showHeadSearch: true,
    toastMessage: "",
    toastDialogShow: false,
    slideShowImage: "",
    isLoadingShow: true,
    isShowFooter: true,
  });

  const [mainHeader, setMainHeader] = useState({
    showHeader: true,
    headerType: 2,
  });

  const [productDetails, setProductDetails] = useState({
    productId: "",
    productName: "",
    productDescription: "",
    productBrand: "",
    productPrice: 0,
    productOffer: 0,
    productOfferedPrice: 0,
    productPricingHtml: "",
    productSlugURL: "",
    productReturnPolicy: "",
    productSizes: [],
    productTags: [],
    productImageList: [],
  });

  const [dialogV2, setDialogV2] = useState({
    showDialog: false,
    dialogTitle: "Select Size!",
    sizesList: [],
  });

  const [dialogZoomImage, setDialogZoomImage] = useState({
    showDialog: false,
    dialogImage: "",
  });

  const updateZoomDialogImage = (data) => {
    setDialogZoomImage((previousState) => {
      return { ...previousState, dialogImage: data };
    });
  };

  const updateZoomDialog = (data) => {
    setDialogZoomImage((previousState) => {
      return { ...previousState, showDialog: data };
    });
  };

  const updateProductSizingPricing = (size) => {
    productDetails.productSizes.map((data, index) => {
      if (data["product_size"] == size) {
        setProductSize(size);

        let productOfferedPrice = Math.ceil(
          Number(data["product_price"]) -
            Number(data["product_price"]) *
              (Number(data["product_offer"]) / 100)
        );

        setProductDetails((previousState) => {
          return {
            ...previousState,
            productPrice: data["product_price"],
            productOffer: data["product_offer"],
            productOfferedPrice: productOfferedPrice,
          };
        });
      }
    });
  };

  const updateDialogV2 = (data, type, e) => {
    if (
      data == false &&
      (e.target.classList.value ==
        "pos-fx pos-top-0 pos-rgt-0 w-100 h-100vh v-center z-i-1000 bg-l-black " ||
        e.target.classList.value == "v-center h-w-28 br-5 bg-l-red" ||
        e.target.classList.value == "pd-5-10 br-5 bg-l-blue")
    ) {
      setDialogV2((previousState) => {
        return { ...previousState, showDialog: false };
      });
    } else {
      setDialogV2((previousState) => {
        return { ...previousState, showDialog: true };
      });
    }

    if (type != "" && type != "close") {
      setProductSize(type);
      addToBag();
    }
  };

  const goToNextSlide = () => {
    console.log(productDetails.productImageList.length);

    if (slideShowIndex < productDetails.productImageList.length - 1) {
      setSlideShowIndex(slideShowIndex + 1);
    } else {
      setSlideShowIndex(0);
    }
  };

  const updateShowReturnPolicy = () => {
    if (isShowReturnPolicy) {
      setShowReturnPolicy(false);
    } else {
      setShowReturnPolicy(true);
    }
  };

  const updateShowDescription = () => {
    if (isShowDescription) {
      setShowDescription(false);
    } else {
      setShowDescription(true);
    }
  };

  const updateLoadingStatus = (data) => {
    setConstants((previousState) => {
      return { ...previousState, isLoadingShow: data };
    });
  };

  const updateToastDialogState = (data, msg) => {
    setConstants((previousState) => {
      return { ...previousState, toastDialogShow: data };
    });

    setConstants((previousState) => {
      return { ...previousState, toastMessage: msg };
    });
  };

  const updateSlideShowIndex = (data) => {
    if (data == "next") {
      setSlideShowIndex(slideShowIndex + 1);
    } else if (slideShowIndex > 1) {
      setSlideShowIndex(slideShowIndex - 1);
    }
  };

  const updateDialogData = (data) => {
    let tempArr = [];

    data.map((data, index) => tempArr.push(data["product_size"]));

    setDialogV2((previousState) => {
      return {
        ...previousState,
        sizesList: tempArr,
      };
    });
  };

  function getProductDetails() {
    const fecthApiData = async (url) => {
      try {
        const res = await fetch(url, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Route: "route-product-details",
            AuthToken: getStorage("secret"),
          },
        });
        const data = await res.json();

        if (data.status_code == "success") {
          console.log(data);

          const productTags = data.productData[0]["product_tags"].split(",");

          setProductDetails({
            ...productDetails,
            productId: data.productData[0]["product_id"],
            productName: data.productData[0]["product_name"],
            productPrice: data.productData[0]["product_price"],
            productOfferedPrice: data.productData[0]["product_offered_price"],
            productOffer: data.productData[0]["product_offer"],
            productDescription: data.productData[0]["product_description"],
            productImageList: data.productData[0]["product_images"],
            productReturnPolicy: data.productData[0]["product_return_policy"],
            productTags: productTags,
            productSizes: data.productData[0]["product_price_size"],
          });

          updateDialogData(data.productData[0]["product_price_size"]);

          setIsDataLoaded(true);
          updateLoadingStatus(false);
        }
      } catch (error) {
        updateLoadingStatus(false);
      }
    };

    if (!isDataLoaded) {
      updateLoadingStatus(true);
      fecthApiData(
        API_ACCESS_URL +
          "?USER_ID=" +
          getStorage("uid") +
          "&PRODUCT=" +
          urlParam
      );
    }
  }

  const addToBag = () => {
    async function requestAPI(url, formData) {
      try {
        const res = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            route: "route-add-to-bag",
          },
          body: JSON.stringify(formData),
        });

        const data = await res.json();
        updateLoadingStatus(false);

        if (data.status_code == "success") {
          setStorage("accountBagItems", data.total_bag_items);
        } else if (data.account_status == "inactive") {
          navigate("/account-update-required", { replace: true });
        } else if (data.account_status == "unauthorized") {
          if (deleteStorage()) {
            navigate("/signin", { replace: true });
          }
        }
      } catch (error) {
        updateLoadingStatus(false);
      }
    }

    if (getStorage("accountID") != "" && urlParam != "") {
      let productSize = selectedProductSize;
      if (productSize == "") {
        productSize = "nosize";
      }
      updateLoadingStatus(true);

      const formData = {
        ACCOUNT_ID: getStorage("accountID"),
        PRODUCT_ID: productDetails.productId,
        PRODUCT_SIZE: productSize,
      };
      requestAPI(API_ACCESS_URL, formData);
    } else {
      navigate("/signin", { replace: true });
    }
  };

  useEffect(() => {
    if (urlParam != "") {
      getProductDetails();

      resetTimeout();

      if (productDetails.productImageList.length > 0) {
        timeoutRef.current = setTimeout(
          () =>
            setSlideShowIndex((prevIndex) =>
              prevIndex === productDetails.productImageList.length - 1
                ? 0
                : prevIndex + 1
            ),
          delay
        );
      }
    }

    return () => {
      resetTimeout();
    };
  }, [productDetails.productImageList, slideShowIndex]);

  return (
    <div className="v-center app-bg">
      <div className="h-100vh w-100 ovf-scrl-y">
        <ToastDialog
          intentData={pageConst}
          updateState={updateToastDialogState}
        />
        <LoadingDialog intentData={pageConst} />
        <DialogV2 intentData={dialogV2} updateState={updateDialogV2} />
        <DialogZoomImage
          intentData={dialogZoomImage}
          updateState={updateZoomDialog}
        />

        <MainHeader intentData={mainHeader} />

        <div className="min-h-100vh">
          <div
            className={`col-view resp-width margin-0-auto ${
              !isDataLoaded ? "hide-v" : ""
            }`}
          >
            <div className="pd-10-15">
              <label className="ft-sz-14">Home / {urlParam}</label>
            </div>

            <div className="productDetailsContainer">
              <div className="productSliderWrapper">
                <Splide
                  options={{
                    perPage: 1,
                    gap: "1rem",
                  }}
                >
                  {productDetails.productImageList.map((data, index) => (
                    <SplideSlide>
                      <ImageLoader
                        className="objectFitContain lazy-loading w-100 max-h-500px obj-f-contain"
                        src={data}
                        onClick={() => [
                          updateZoomDialogImage(data),
                          updateZoomDialog(true),
                        ]}
                        alt="product-image"
                      />
                    </SplideSlide>
                  ))}
                </Splide>
              </div>

              <div className="productDetailsWrapper">
                <h1 className="ft-sz-16 ft-wgt-500">
                  {productDetails.productName}
                </h1>
                <div className="col-view pd-15-0">
                  <div className="row-view">
                    <span className="ft-sz-25 ft-wgt-600">
                      ₹{productDetails.productOfferedPrice}
                    </span>
                    <s
                      className={`ft-sz-15 mg-l-5 ${
                        productDetails.productOffer <= 0 ? "hide-v" : ""
                      }`}
                    >
                      ₹{productDetails.productPrice}
                    </s>
                    <span
                      className={`ft-sz-16 ft-wgt-500 cl-green mg-l-10 ${
                        Number(productDetails.productOffer) <= 0 ? "hide-v" : ""
                      }`}
                    >
                      {productDetails.productOffer}% OFF
                    </span>
                  </div>

                  <span className="ft-sz-12 mg-t-5">
                    inclusive of all taxes
                  </span>
                </div>

                <div
                  className={`w-100 row-view gap-0p5 flex-wrap mg-t-10 ${
                    productDetails.productTags == "" ? "hide-v" : ""
                  }`}
                >
                  {productDetails.productTags.map((tag, index) => (
                    <div
                      key={index}
                      className="max-w-250 pd-2-8 ft-sz-12 br-a-grey ovf-hidden"
                    >
                      {tag}
                    </div>
                  ))}
                </div>

                <div className="line-hv-grey h-3-p mg-t-20"></div>

                <div
                  className={`col-view w-100 mg-t-25 ${
                    productDetails.productSizes == "" ? "hide-v" : ""
                  }`}
                >
                  <div className="w-100 row-view sb-view">
                    <div className="ft-sz-14 ft-wgt-600">
                      Select Size{" "}
                      <span
                        className={`ft-sz-13 cl-red ${
                          selectedProductSize != "" ? "hide-v" : ""
                        }`}
                      >
                        (Please select size)
                      </span>
                    </div>
                    <div className="ft-sz-14">Size Guide</div>
                  </div>

                  <div className="w-100 row-view gap-0p5 flex-wrap mg-t-15">
                    {productDetails.productSizes != null
                      ? productDetails.productSizes.map((data, index) => (
                          <label
                            className={`min-w-40-p max-w-130px v-center pd-10 br-5 ${
                              selectedProductSize == data["product_size"]
                                ? "cl-white bg-black"
                                : "cl-back bg-grey"
                            }`}
                            onClick={() =>
                              updateProductSizingPricing(data["product_size"])
                            }
                          >
                            {data["product_size"]}
                          </label>
                        ))
                      : ""}
                  </div>
                </div>

                <div className="productBtnsContainer w-100 mg-t-30">
                  <div
                    className="v-center bg-primary"
                    onClick={() => addToBag()}
                  >
                    <img
                      className="h-w-20"
                      src={require("../media/icons/cart_icon.png")}
                      alt="icon"
                    />
                    <span className="ft-sz-14 ft-wgt-600 mg-l-10">
                      Add to Bag
                    </span>
                  </div>

                  <div className="v-center">
                    <img
                      className="h-w-20"
                      src={require("../media/icons/heart_icon.png")}
                      alt="icon"
                    />
                    <span className="ft-sz-14 ft-wgt-600 mg-l-10">
                      Wishlist
                    </span>
                  </div>
                </div>

                <div className="line-hv-grey h-3-p mg-t-20"></div>

                <div className="col-view w-100 mg-t-20">
                  <div
                    className="row-view sb-view cursor-pointer"
                    onClick={() =>
                      isShowAvilableOffers
                        ? setShowAvilableOffers(false)
                        : setShowAvilableOffers(true)
                    }
                  >
                    <div className="row-view">
                      <img
                        className="h-w-26"
                        src={require("../media/icons/star_icon.png")}
                        alt="icon"
                      />
                      <div className="mg-l-15">
                        <p className="ft-sz-14 ft-wgt-600">Avilable Offers</p>
                        <p className="ft-sz-12 mg-t-5">
                          Manufacture, Care and Fit
                        </p>
                      </div>
                    </div>

                    <div className="ft-sz-25">
                      {!isShowAvilableOffers ? "+" : "-"}
                    </div>
                  </div>

                  <div
                    className={`col-view pd-10-0 ${
                      !isShowAvilableOffers ? "hide-v" : ""
                    }`}
                  >
                    <p
                      className="ft-sz-12"
                      dangerouslySetInnerHTML={{
                        __html: productDetails.productDescription,
                      }}
                    ></p>
                  </div>
                </div>

                <div className="line-hv-grey h-3-p mg-t-20"></div>

                <div className="col-view w-100 mg-t-20">
                  <div
                    className="row-view sb-view cursor-pointer"
                    onClick={() =>
                      isShowDescription
                        ? setShowDescription(false)
                        : setShowDescription(true)
                    }
                  >
                    <div className="row-view">
                      <img
                        className="h-w-26"
                        src={require("../media/icons/description_icon.png")}
                        alt="icon"
                      />
                      <div className="mg-l-15">
                        <p className="ft-sz-14 ft-wgt-600">
                          Product Description
                        </p>
                        <p className="ft-sz-12">Manufacture, Care and Fit</p>
                      </div>
                    </div>

                    <div className="ft-sz-25">
                      {!isShowDescription ? "+" : "-"}
                    </div>
                  </div>

                  <div
                    className={`col-view pd-10-0 ${
                      !isShowDescription ? "hide-v" : ""
                    }`}
                  >
                    <p
                      className="ft-sz-12"
                      dangerouslySetInnerHTML={{
                        __html: productDetails.productDescription,
                      }}
                    ></p>
                  </div>
                </div>

                <div className="line-hv-grey h-3-p mg-t-20"></div>

                <div className="col-view w-100 mg-t-20">
                  <div
                    className="row-view sb-view cursor-pointer"
                    onClick={() =>
                      isShowReturnPolicy
                        ? setShowReturnPolicy(false)
                        : setShowReturnPolicy(true)
                    }
                  >
                    <div className="row-view">
                      <img
                        className="h-w-26"
                        src={require("../media/icons/exchange_icon.png")}
                        alt="icon"
                      />
                      <div className="mg-l-15">
                        <p className="ft-sz-14 ft-wgt-600">
                          Return & Exchange Policy
                        </p>
                        <p className="ft-sz-12">
                          Know about return & exchange policy
                        </p>
                      </div>
                    </div>

                    <div className="ft-sz-25">
                      {!isShowReturnPolicy ? "+" : "-"}
                    </div>
                  </div>

                  <div
                    className={`col-view pd-10-0 ${
                      !isShowReturnPolicy ? "hide-v" : ""
                    }`}
                  >
                    <p
                      className="ft-sz-12"
                      dangerouslySetInnerHTML={{
                        __html: productDetails.productReturnPolicy,
                      }}
                    ></p>
                  </div>
                </div>

                <div className="line-hv-grey h-3-p mg-t-20"></div>

                <div className="w-100 row-view sb-view mg-t-30">
                  <div className="col-view v-center">
                    <img
                      className="w-50-p"
                      src="https://images.bewakoof.com/web/trust-cart.svg"
                      alt="icon"
                    />
                    <span className="txt-a-center ft-sz-8">
                      100% SECURE PAYMENTS
                    </span>
                  </div>

                  <div className="col-view v-center">
                    <img
                      className="w-50-p"
                      src="https://images.bewakoof.com/web/Easy-Returns.svg"
                      alt="icon"
                    />
                    <span className="txt-a-center ft-sz-8">
                      EASY RETURNS & INSTANT REFUNDS
                    </span>
                  </div>

                  <div className="col-view v-center">
                    <img
                      className="w-50-p"
                      src="https://images.bewakoof.com/web/original-icon.png"
                      alt="icon"
                    />
                    <span className="txt-a-center ft-sz-8">
                      100% GENUINE PRODUCT
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer intentData={pageConst} />
      </div>
    </div>
  );
}

export default ViewProduct;
