import React from 'react'
import { Link } from 'react-router-dom';
import { getStorage } from '../modals/Storage';
import { WEBSITE_LOGO_ORIGINAL, WEBSITE_NAME, openNewPage } from "../modals/Constants";

function Footer({intentData}){
    return (
        <div className={`col-view v-center w-100 pd-75-15 bg-black ${!intentData.isShowFooter ? 'hide-v' : ''}`}>
          <div className='resp-width mg-b-15'>
            <Link className='txt-deco-n cl-white ft-sz-25' to={"/"}>{WEBSITE_NAME}</Link>

            <div className='resp-gridview-3 mg-t-30'>
              <div className='col-view'>
                <span className='ft-sz-18 cl-white mg-b-10'>Imp Links</span>
                <Link className='txt-deco-n ft-sz-13 cl-white pd-5-0' onClick={() => openNewPage(getStorage("refundPolicyURL"))}>Refund Policy</Link>
                <Link className='txt-deco-n ft-sz-13 cl-white pd-5-0' onClick={() => openNewPage(getStorage("privacyPolicyURL"))}>Privacy Policy</Link>
              </div>

              <div className='col-view'>
                <span className='ft-sz-18 cl-white mg-b-10'>Categories</span>
                {getStorage("categoryArr")!= '' ? JSON.parse(getStorage("categoryArr")).map((data, index) => (
                <Link key={index} className='txt-deco-n ft-sz-13 cl-white pd-5-0' to={"/c/"+data['category_slug']}>
                  {data['category_name']}
                </Link>
                )) : ''}
              </div>

              {/* <div className='col-view'>
                <Link className='txt-deco-n ft-sz-13 cl-white pd-5-0' to={"/"}>About us</Link>
                <Link className='txt-deco-n ft-sz-13 cl-white pd-5-0' to={"/"}>Terms & Condition</Link>
                <Link className='txt-deco-n ft-sz-13 cl-white pd-5-0' to={"/"}>Privacy Policy</Link>
              </div>

              <div className='col-view'>
                <Link className='txt-deco-n ft-sz-13 cl-white pd-5-0' to={"/"}>About us</Link>
                <Link className='txt-deco-n ft-sz-13 cl-white pd-5-0' to={"/"}>Terms & Condition</Link>
                <Link className='txt-deco-n ft-sz-13 cl-white pd-5-0' to={"/"}>Privacy Policy</Link>
              </div> */}
            </div>

          </div>
        </div>
    );
}

export default Footer;