import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { getStorage, deleteStorage } from "../modals/Storage";
import {
  generateAuthToken,
  openNewPage,
  redirectTo,
} from "../modals/Constants";
import MainHeader from "../other-components/MainHeader";
import general from "../css/General.module.css";

function My() {
  const navigate = useNavigate();

  const [pageConst, setConstants] = useState({
    showHeadSearch: true,
    toastMessage: "",
    toastDialogShow: false,
    isShowFooter: true,
    isLoadingShow: false,
  });

  const [mainHeader, setMainHeader] = useState({
    showHeader: true,
    headerType: 2,
  });

  const signOutAccount = () => {
    if (deleteStorage()) {
      navigate("/signin", { replace: true });
    }
  };

  useEffect(() => {
    if (getStorage("accountID") == "") {
      navigate("/signin", { replace: true });
    }
  }, []);

  return (
    <div className="v-center app-bg">
      <div className="h-100vh w-100 ovf-scrl-y">
        <div className="w-100 col-view a-center">
          <MainHeader intentData={mainHeader} />

          <div className="col-view min-h-100vh resp-width margin-0-auto">
            <div className="accountOptionsContainer">
              <h3 className="ft-sz-20 ft-wgt-600">My Account</h3>

              <div className="accountOptionsContainerWrapper mg-t-55">
                <Link
                  className="txt-deco-n col-view cl-black pd-15"
                  to={"/update-account"}
                >
                  <div>
                    <span
                      className={[
                        general.fontSize16px,
                        general.fontWgt600,
                      ].join(" ")}
                    >
                      My Profile
                    </span>
                    <svg
                      viewBox="-19.04 0 75.804 75.804"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="#000000"
                      className="h-w-12 mg-l-10"
                    >
                      <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                      <g
                        id="SVGRepo_tracerCarrier"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></g>
                      <g id="SVGRepo_iconCarrier">
                        {" "}
                        <g
                          id="Group_65"
                          data-name="Group 65"
                          transform="translate(-831.568 -384.448)"
                        >
                          {" "}
                          <path
                            id="Path_57"
                            data-name="Path 57"
                            d="M833.068,460.252a1.5,1.5,0,0,1-1.061-2.561l33.557-33.56a2.53,2.53,0,0,0,0-3.564l-33.557-33.558a1.5,1.5,0,0,1,2.122-2.121l33.556,33.558a5.53,5.53,0,0,1,0,7.807l-33.557,33.56A1.5,1.5,0,0,1,833.068,460.252Z"
                            fill="#0c2c67"
                          ></path>{" "}
                        </g>{" "}
                      </g>
                    </svg>
                  </div>

                  <span className="ft-sz-13 mg-t-5">
                    Edit personal info, change password
                  </span>
                </Link>

                <Link
                  className="txt-deco-n col-view cl-black pd-15"
                  to={"/addresses"}
                >
                  <div>
                    <span
                      className={[
                        general.fontSize16px,
                        general.fontWgt600,
                      ].join(" ")}
                    >
                      My Addresses
                    </span>
                    <svg
                      viewBox="-19.04 0 75.804 75.804"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="#000000"
                      className="h-w-12 mg-l-10"
                    >
                      <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                      <g
                        id="SVGRepo_tracerCarrier"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></g>
                      <g id="SVGRepo_iconCarrier">
                        {" "}
                        <g
                          id="Group_65"
                          data-name="Group 65"
                          transform="translate(-831.568 -384.448)"
                        >
                          {" "}
                          <path
                            id="Path_57"
                            data-name="Path 57"
                            d="M833.068,460.252a1.5,1.5,0,0,1-1.061-2.561l33.557-33.56a2.53,2.53,0,0,0,0-3.564l-33.557-33.558a1.5,1.5,0,0,1,2.122-2.121l33.556,33.558a5.53,5.53,0,0,1,0,7.807l-33.557,33.56A1.5,1.5,0,0,1,833.068,460.252Z"
                            fill="#0c2c67"
                          ></path>{" "}
                        </g>{" "}
                      </g>
                    </svg>
                  </div>

                  <span className="ft-sz-13 mg-t-5">
                    Edit, add or remove addresses
                  </span>
                </Link>

                <Link
                  className="txt-deco-n col-view cl-black pd-15"
                  to={"/orders"}
                >
                  <div>
                    <span
                      className={[
                        general.fontSize16px,
                        general.fontWgt600,
                      ].join(" ")}
                    >
                      My Orders
                    </span>
                    <svg
                      viewBox="-19.04 0 75.804 75.804"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="#000000"
                      className="h-w-12 mg-l-10"
                    >
                      <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                      <g
                        id="SVGRepo_tracerCarrier"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></g>
                      <g id="SVGRepo_iconCarrier">
                        {" "}
                        <g
                          id="Group_65"
                          data-name="Group 65"
                          transform="translate(-831.568 -384.448)"
                        >
                          {" "}
                          <path
                            id="Path_57"
                            data-name="Path 57"
                            d="M833.068,460.252a1.5,1.5,0,0,1-1.061-2.561l33.557-33.56a2.53,2.53,0,0,0,0-3.564l-33.557-33.558a1.5,1.5,0,0,1,2.122-2.121l33.556,33.558a5.53,5.53,0,0,1,0,7.807l-33.557,33.56A1.5,1.5,0,0,1,833.068,460.252Z"
                            fill="#0c2c67"
                          ></path>{" "}
                        </g>{" "}
                      </g>
                    </svg>
                  </div>

                  <span className="ft-sz-13 mg-t-5">
                    View, modify and track orders
                  </span>
                </Link>
              </div>

              <div className="line-hv-grey mg-t-20"></div>

              <div className="accountOptionsContainerWrapper">
                <Link
                  className="txt-deco-n col-view cl-black pd-15"
                  to={"/cart"}
                >
                  <div>
                    <span
                      className={[
                        general.fontSize16px,
                        general.fontWgt600,
                      ].join(" ")}
                    >
                      My Bag
                    </span>
                    <svg
                      viewBox="-19.04 0 75.804 75.804"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="#000000"
                      className="h-w-12 mg-l-10"
                    >
                      <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                      <g
                        id="SVGRepo_tracerCarrier"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></g>
                      <g id="SVGRepo_iconCarrier">
                        {" "}
                        <g
                          id="Group_65"
                          data-name="Group 65"
                          transform="translate(-831.568 -384.448)"
                        >
                          {" "}
                          <path
                            id="Path_57"
                            data-name="Path 57"
                            d="M833.068,460.252a1.5,1.5,0,0,1-1.061-2.561l33.557-33.56a2.53,2.53,0,0,0,0-3.564l-33.557-33.558a1.5,1.5,0,0,1,2.122-2.121l33.556,33.558a5.53,5.53,0,0,1,0,7.807l-33.557,33.56A1.5,1.5,0,0,1,833.068,460.252Z"
                            fill="#0c2c67"
                          ></path>{" "}
                        </g>{" "}
                      </g>
                    </svg>
                  </div>

                  <span className="ft-sz-13 mg-t-5">
                    View, remove and order products
                  </span>
                </Link>

                <div
                  className="col-view cl-black pd-15 bg-l-red"
                  onClick={() => signOutAccount()}
                >
                  <div>
                    <span className="cl-red ft-sz-17 ft-wgt-500">Logout</span>
                    <svg
                      viewBox="-19.04 0 75.804 75.804"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="#fa3c09"
                      className="h-w-12 mg-l-10"
                    >
                      <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                      <g
                        id="SVGRepo_tracerCarrier"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></g>
                      <g id="SVGRepo_iconCarrier">
                        {" "}
                        <g
                          id="Group_65"
                          data-name="Group 65"
                          transform="translate(-831.568 -384.448)"
                        >
                          {" "}
                          <path
                            id="Path_57"
                            data-name="Path 57"
                            d="M833.068,460.252a1.5,1.5,0,0,1-1.061-2.561l33.557-33.56a2.53,2.53,0,0,0,0-3.564l-33.557-33.558a1.5,1.5,0,0,1,2.122-2.121l33.556,33.558a5.53,5.53,0,0,1,0,7.807l-33.557,33.56A1.5,1.5,0,0,1,833.068,460.252Z"
                            fill="#0c2c67"
                          ></path>{" "}
                        </g>{" "}
                      </g>
                    </svg>
                  </div>

                  <span className="ft-sz-13 mg-t-5">
                    Remove account from this device
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default My;
