import React, { useEffect, useState } from 'react'
import { Link,useNavigate } from 'react-router-dom';
import ToastDialog from '../dialogs/ToastDialog';
import MainHeader from '../other-components/MainHeader';
import '../../MainStyle.css';
import { API_ACCESS_URL } from '../modals/Constants';
import { setStorage,getStorage } from '../modals/Storage';
import LoadingDialog from '../dialogs/LoadingDialog';


function OtpVerification(){
  const navigate = useNavigate();

  const [inOTP, setInOTP] = useState("");

  const [pageConst, setConstants] = useState({
      pageTitle: "Verification",
      inMobileNum: "",
      inPassword: "",
      isLoadingShow: false,
      toastDialogShow: false,
      toastTimeAvail: 7,
      toastMessage: "",
      isSessionExist: true,
  });

    const topBarClickAction = (data) =>{
      if(data=="multiBtn1"){
        navigate('/withdraw', { replace: false });
      }

      if(data=="multiBtn2"){
        navigate('/register', { replace: false });
      }
    }

    const updateLoadingStatus = (data) => {
        setConstants(previousState => {
            return { ...previousState, isLoadingShow: data }
        });
    }

    const updateToastDialogState = (data,msg) => {
      setConstants(previousState => {
        return { ...previousState, toastDialogShow: data }
      });

      setConstants(previousState => {
        return { ...previousState, toastMessage: msg }
      });
    }

    const validateOTP = () => {

      async function requestAPI(url,formData) {
        try {
          const res = await fetch(url, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'route': 'route-verify-otp',
            },
            body: JSON.stringify(formData),
          });
          
          const data = await res.json();
          updateLoadingStatus(false);

          if(data.status_code=="signin_successful"){
            setStorage("accountID", data.account_id);
            setStorage("accountSecret", data.account_secret);

            setStorage("accountEmail", data.account_email);
            setStorage("accountMobile", data.account_mobilenum);
            setStorage("accountFullName", data.account_fullname);
            
            navigate('/', { replace: true });
          }else if(data.status_code=="onboard_required"){
            setStorage("accountID", data.account_id);
            setStorage("accountSecret", data.account_secret);

            setStorage("accountEmail", data.account_email);
            setStorage("accountMobile", data.account_mobilenum);
            setStorage("accountFullName", data.account_fullname);

            navigate('/onboard', { replace: true });
          }

        } catch (error) {
          updateLoadingStatus(false);
          updateToastDialogState(true,"There was a technical issue! Please try again!");
        }
      };

      if(getStorage("accountID")!='' && getStorage("accountMobile")!='' && inOTP.length > 4){
        const formData = { ACCOUNT_ID: getStorage("accountID"), SIGNIN_MOBILE: getStorage("accountMobile"), SIGNIN_OTP: inOTP};
        requestAPI(API_ACCESS_URL,formData);
      }
    }
  

    useEffect(() => {

      if(getStorage("accountID")==''){
        navigate('/signin', { replace: true });
      }

    }, []);

    return (
      <div className={`v-center app-bg ${pageConst.isSessionExist == true ? '' : ''}`}>
        <div className="h-100vh w-100">
          <ToastDialog intentData={pageConst} updateState={updateToastDialogState} />
          <LoadingDialog intentData={pageConst}/>

          <div className='ps-rl h-100 ovf-scrl-y hide-sb scrn-anim-view'>
            <MainHeader intentData={pageConst} multiBtn1="" updateState={topBarClickAction}/>

            <div className='split2ViewContainer h-100 mg-t-70'>
              <div className='split2ViewWrapper bg-welcomeview v-center flex-d-col pd-10'>
                <h2>Welcome to the world of Bewakoof</h2>
                <img className="w-85" src={require('../media/illustrations/1.webp')} />
              </div>

              <div className='split2ViewWrapper v-center flex-d-col'>

                <div className='split2ViewWrapperContents pd-30-5'>
                  <h1 className='ft-sz-20'>OTP Verification</h1>
                  <p className='ft-sz-14 mg-t-10'>We have sent verification code to +91{getStorage("accountMobile")}</p>
                  <div className='row-view pd-10 bg-white mg-t-30 br-10 br-a-grey'>
                    <input type="number" className='w-100 inp-box ft-sz-16 inp-ph-color-l-white bg-white' placeholder='Verificaion Code' autoComplete="off" onChange={(e) => setInOTP(e.target.value)}></input>
                  </div>

                  <div className={`h-50-p v-center ft-sz-20 pd-10-20 br-5 cl-white mg-t-20 ${inOTP.length > 4 ? 'bg-primary' : 'bg-disable-view'}`} onClick={() => validateOTP()}>
                    <span className={`ft-wgt-600 ${pageConst.isLoadingShow==true ? 'hide-v' : ''}`}>Continue</span>
                  </div>
                </div>

              </div>

            </div>

          </div>
        </div>
      </div>
    );
}

export default OtpVerification;