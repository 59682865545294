import React from 'react'
import '../../MainStyle.css';

const DialogV2 = ({intentData,updateState}) => {

    return (
        <div className={`pos-fx pos-top-0 pos-rgt-0 w-100 h-100vh v-center z-i-1000 bg-l-black ${intentData.showDialog ? '' : 'hide-v'}`} onClick={(e)=>updateState(false,"close",e)}>
            <div className='dialogV2Container'>
              <div className='row-view sb-view'>
                <span className='mg-t-10 ft-sz-17 ft-wgt-600'>{intentData.dialogTitle}</span>
                <span className='v-center h-w-28 br-5 bg-l-red'>✕</span>
              </div>

              <div className='row-view flex-wrap gap-0p5 mg-t-20'>
               {intentData.sizesList!=null ? intentData.sizesList.map((data, index) => (
                 <div key={index} className='pd-5-10 br-5 bg-l-blue' onClick={(e) => updateState(false,data,e)}>{data}</div>
               )) : ''}
              </div>
                
            </div>
        </div>
    );
};

export default DialogV2;