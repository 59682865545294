import React, { useState, useEffect } from "react";
import { Form, Link, useNavigate } from "react-router-dom";
import { getStorage, setStorage, deleteStorage } from "../modals/Storage";
import {
  openNewPage,
  WEBSITE_LOGO_ORIGINAL,
  WEBSITE_NAME,
} from "../modals/Constants";

const MainHeader = ({ intentData }) => {
  const navigate = useNavigate();
  const [showSideMenu, setSideMenu] = useState(false);

  const navigateToLogin = () => {
    if (getStorage("accountID") == "" || getStorage("accountSecret") == "") {
      navigate("/signin", { replace: false });
    }
  };

  const handleSubmit = (e) => {
    if (e.target[0].value != "") {
      navigate("/search/" + e.target[0].value, { replace: false });
    }
    e.preventDefault();
  };

  const updateSideMenu = () => {
    if (showSideMenu) {
      setSideMenu(false);
    } else {
      setSideMenu(true);
    }
  };

  return (
    <nav className="mainHeader col-view">
      <div className="w-100 row-view sb-view a-center pd-10-25">
        <div className="row-view">
          <label
            className="mOpenMenu mg-r-15 v-center"
            onClick={() => updateSideMenu()}
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24"><path stroke="#303030" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="M4 12h12M4 5h16M4 19h16"/></svg>
          </label>

          <Link
            className="mLogoOriginal txt-deco-n cl-black fontWt800"
            to={"/"}
          >
            {WEBSITE_NAME}
            {/* <img className='h-22-p' src={WEBSITE_LOGO_ORIGINAL} alt="icon" /> */}
          </Link>
        </div>

        <div className="row-view">
          <form
            className={`${intentData.headerType > 1 ? "hide-v" : ""}`}
            onSubmit={(e) => handleSubmit(e)}
          >
            <div className="menuSearchContainer h-40-p w-210-p row-view pd-10 br-5 bg-l-grey">
              <div className="row-view pd-5 bg-transparent">
                <i className="bx bx-search ft-sz-18"></i>
              </div>

              <input
                type="text"
                className="inp-box ft-sz-12 inp-ph-color-l-white bg-transparent"
                placeholder="Search by product, category or collection"
                autoComplete="off"
              ></input>
            </div>
          </form>

          <Link
            className="mSearchView v-center txt-deco-n pd-5 gap-0p5 mg-l-10"
            to={"/search/def"}
          >
            <i className="bx bx-search cl-black ft-sz-25"></i>
          </Link>

          <Link
            className="mAccountView v-center txt-deco-n pd-5 gap-0p5 mg-l-10"
            to={"/account"}
          >
            <img
              className="h-w-22"
              src={require("../media/icons/account_icon.png")}
              alt="icon"
            />
            <span className="cl-black ft-sz-16 ft-wgt-500">Account</span>
          </Link>

          <Link
            className="mCartView v-center txt-deco-n pd-5 gap-0p5 mg-l-10"
            to={"/cart"}
          >
            <div className="pos-rel">
              <label className="h-w-26 v-center pos-abs h-w-15 pos-top--5px pos-rgt--5px ft-sz-12 cl-white br-50 bg-primary">
                {getStorage("accountBagItems") == ""
                  ? "0"
                  : getStorage("accountBagItems")}
              </label>
              <img
                className="h-w-22"
                src={require("../media/icons/cart_icon.png")}
                alt="icon"
              />
            </div>

            <span className="cl-black ft-sz-16 ft-wgt-500">Cart</span>
          </Link>
        </div>
      </div>

      <div
        className={`pos-fx pos-top-0 pos-btm-0 w-100 h-100vh z-i-1000 bg-l-black ovf-hidden ${
          showSideMenu ? "pos-left-0" : "pos-left--100"
        }`}
        onClick={() => updateSideMenu()}
      >
        <div
          className={`pos-fx pos-top-0 pos-left-0 pos-btm-0 w-310-p h-100vh col-view trn-05 bg-white ovf-scrl-y hide-sb ${
            showSideMenu ? "pos-left-0" : "pos-left--100"
          }`}
        >
          <div
            className="w-100 col-view mg-t-65 pd-30-15"
            onClick={() => navigateToLogin()}
          >
            <Link className="txt-deco-n row-view cl-black">
              <div
                className={`h-w-32 v-center mg-r-15 br-50 bg-primary ${
                  getStorage("accountID") == "" ? "hide-v" : ""
                }`}
              >
                <h5 className="ft-sz-18">
                  {getStorage("accountFullName").charAt(0).toUpperCase()}
                </h5>
              </div>
              <div>
                <h5 className="ft-sz-18">
                  {getStorage("accountID") != "" &&
                  getStorage("accountSecret") != ""
                    ? "Hey " + getStorage("accountFullName").split(" ")[0]
                    : "Welcome, Guest"}
                </h5>
                <span className="ft-sz-14 ft-wgt-500 mg-t-5">
                  {getStorage("accountID") != ""
                    ? "Go to Account"
                    : "Log In/ Sign Up"}
                </span>
              </div>
            </Link>
          </div>

          <div className="line-hv-grey mg-t-10"></div>

          <div className={`mg-t-10`}>
            {getStorage("categoryArr") != ""
              ? JSON.parse(getStorage("categoryArr")).map((data, index) => (
                  <Link
                    key={index}
                    className={`display-block txt-deco-n cl-black pd-15`}
                    to={"/c/" + data["category_slug"]}
                  >
                    <span className="ft-sz-14 ft-wgt-600">
                      {data["category_name"]}
                    </span>
                  </Link>
                ))
              : ""}
          </div>

          <div
            className={`${
              getStorage("accountID") == "" || getStorage("accountSecret") == ""
                ? "hide-v"
                : ""
            }`}
          >
            <div className="line-hv-grey mg-t-10"></div>

            <Link
              className={`display-block txt-deco-n cl-black pd-15 mg-t-10`}
              to={"/account"}
            >
              <span className="ft-sz-14 ft-wgt-600">My Account</span>
            </Link>

            <Link
              className={`display-block txt-deco-n cl-black pd-15`}
              to={"/cart"}
            >
              <span className="ft-sz-14 ft-wgt-600">My Cart</span>
            </Link>

            <Link
              className={`display-block txt-deco-n cl-black pd-15`}
              to={"/orders"}
            >
              <span className="ft-sz-14 ft-wgt-600">My Orders</span>
            </Link>
          </div>

          <div className="line-hv-grey mg-t-10"></div>

          <div className="mg-t-10">
            <Link
              className={`display-block txt-deco-n cl-black pd-15`}
              onClick={() => openNewPage(getStorage("helpSuportURL"))}
            >
              <span className="ft-sz-14 ft-wgt-600">Help & Support</span>
            </Link>

            {/* <Link className={`display-block txt-deco-n cl-black pd-15`} onClick={() => openNewPage(getStorage("privacyPolicyURL"))}>
                  <span className='ft-sz-14 ft-wgt-600'>Feedback & Suggestions</span>
                </Link> */}

            <Link
              className={`display-block txt-deco-n cl-black pd-15`}
              onClick={() => openNewPage(getStorage("privacyPolicyURL"))}
            >
              <span className="ft-sz-14 ft-wgt-600">Privacy & Policy</span>
            </Link>

            <Link
              className={`display-block txt-deco-n cl-black pd-15`}
              onClick={() => openNewPage(getStorage("refundPolicyURL"))}
            >
              <span className="ft-sz-14 ft-wgt-600">Refund Policy</span>
            </Link>
          </div>
        </div>
      </div>

      <div
        className={`dropDownWrapper w-100 v-center pd-10-20 ${
          intentData.headerType > 1 ? "hide-v" : ""
        }`}
      >
        {getStorage("categoryArr") != ""
          ? JSON.parse(getStorage("categoryArr")).map((data, index) => (
              <Link
                key={index}
                className="cl-black txt-deco-n ft-sz-14 pd-10-15"
                to={"/c/" + data["category_slug"]}
              >
                <span className="ft-sz-16 ft-wgt-500">
                  {data["category_name"]}
                </span>
              </Link>
            ))
          : ""}
      </div>
    </nav>
  );
};

export default MainHeader;
