import React, { useState, useRef, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import ToastDialog from "../dialogs/ToastDialog";
import { API_ACCESS_URL } from "../modals/Constants";
import Footer from "../other-components/Footer";
import MainHeader from "../other-components/MainHeader";
import { deleteStorage, getStorage, setStorage } from "../modals/Storage";
import LoadingDialog from "../dialogs/LoadingDialog";
import ImageLoader from "../other-components/ImageLoader";
import DialogV2 from "../dialogs/DialogV2";

import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/react-splide/css";
import ContainerV6Modal from "../containerModals/ContainerV6Modal";
import UploadShowImage from "../other-components/UploadShowImage";

function CustomizeProduct() {
  const navigate = useNavigate();
  let { urlParam } = useParams();
  const [slideShowIndex, setSlideShowIndex] = useState(0);
  const [selectedProductSize, setProductSize] = useState("");

  const [customUploadedImage, setCustomImage] = useState([]);
  const [isShowDescription, setShowDescription] = useState(false);
  const [isShowReturnPolicy, setShowReturnPolicy] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [isDataLoaded, setIsDataLoaded] = useState("");

  const [pageConst, setConstants] = useState({
    showHeadSearch: true,
    toastMessage: "",
    toastDialogShow: false,
    slideShowImage: "",
    isLoadingShow: true,
    isShowFooter: true,
  });

  const [productDetails, setProductDetails] = useState({
    productId: "",
    productName: "",
    productDescription: "",
    productBrand: "",
    productPrice: 0,
    productOffer: 0,
    productOfferedPrice: 0,
    productPricingHtml: "",
    productSlugURL: "",
    productReturnPolicy: "",
    productSizes: [],
    productTags: [],
    productImageList: [],
  });

  const [mainHeader, setMainHeader] = useState({
    showHeader: true,
    headerType: 2,
  });

  const [dialogV2, setDialogV2] = useState({
    showDialog: false,
    dialogTitle: "Select Size!",
    sizesList: [],
  });

  const updateDialogV2 = (data, type, e) => {
    if (
      data == false &&
      (e.target.classList.value ==
        "pos-fx pos-top-0 pos-rgt-0 w-100 h-100vh v-center z-i-1000 bg-l-black " ||
        e.target.classList.value == "v-center h-w-28 br-5 bg-l-red" ||
        e.target.classList.value == "pd-5-10 br-5 bg-l-blue")
    ) {
      setDialogV2((previousState) => {
        return { ...previousState, showDialog: false };
      });
    } else {
      setDialogV2((previousState) => {
        return { ...previousState, showDialog: true };
      });
    }

    if (type != "" && type != "close") {
      setProductSize(type);
      addToBag();
    }
  };

  const updateProductSizingPricing = (size) => {
    productDetails.productSizes.map((data, index) => {
      if (data["product_size"] == size) {
        setProductSize(size);

        let productOfferedPrice = Math.ceil(
          Number(data["product_price"]) -
            Number(data["product_price"]) *
              (Number(data["product_offer"]) / 100)
        );

        setProductDetails((previousState) => {
          return {
            ...previousState,
            productPrice: data["product_price"],
            productOffer: data["product_offer"],
            productOfferedPrice: productOfferedPrice,
          };
        });
      }
    });
  };

  const onImageUpdate = (image) => {
    setCustomImage(image);
  };

  const updateShowReturnPolicy = () => {
    if (isShowReturnPolicy) {
      setShowReturnPolicy(false);
    } else {
      setShowReturnPolicy(true);
    }
  };

  const updateShowDescription = () => {
    if (isShowDescription) {
      setShowDescription(false);
    } else {
      setShowDescription(true);
    }
  };

  const updateLoadingStatus = (data) => {
    setConstants((previousState) => {
      return { ...previousState, isLoadingShow: data };
    });
  };

  const updateToastDialogState = (data, msg) => {
    setConstants((previousState) => {
      return { ...previousState, toastDialogShow: data };
    });

    setConstants((previousState) => {
      return { ...previousState, toastMessage: msg };
    });
  };

  const updateSlideShowIndex = (data) => {
    if (data == "next") {
      setSlideShowIndex(slideShowIndex + 1);
    } else if (slideShowIndex > 1) {
      setSlideShowIndex(slideShowIndex - 1);
    }
  };

  const updateDialogData = (data) => {
    let tempArr = [];

    data.map((data, index) => tempArr.push(data["product_size"]));

    setDialogV2((previousState) => {
      return {
        ...previousState,
        sizesList: tempArr,
      };
    });
  };

  function getProductDetails() {
    const fecthApiData = async (url) => {
      try {
        const res = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Route: "route-customize-product",
            AuthToken: getStorage("secret"),
          },
        });
        const data = await res.json();

        if (data.status_code == "success") {
          const productTags = data.data[0]["product_tags"].split(",");

          setProductDetails({
            ...productDetails,
            productId: data.data[0]["product_id"],
            productName: data.data[0]["product_name"],
            productPrice: data.data[0]["product_price"],
            productOfferedPrice: data.data[0]["product_offered_price"],
            productOffer: data.data[0]["product_offer"],
            productDescription: data.data[0]["product_description"],
            productImageList: data.data[0]["product_images"],
            productReturnPolicy: data.data[0]["product_return_policy"],
            productTags: productTags,
            productSizes: data.data[0]["product_price_size"],
          });

          updateDialogData(data.data[0]["product_price_size"]);

          setIsDataLoaded(true);
          updateLoadingStatus(false);
        }
      } catch (error) {
        updateLoadingStatus(false);
      }
    };

    if (!isDataLoaded) {
      updateLoadingStatus(true);
      fecthApiData(
        API_ACCESS_URL +
          "?ACCOUNT_ID=" +
          getStorage("uid") +
          "&PRODUCT=" +
          urlParam
      );
    }
  }

  const addToBag = () => {
    async function requestAPI(url, formData) {
      try {
        const res = await fetch(url, {
          method: "POST",
          headers: {
            route: "route-custom-add-to-bag",
          },
          body: formData,
        });

        const data = await res.json();
        updateLoadingStatus(false);

        if (data.status_code == "success") {
          setStorage("accountBagItems", data.total_bag_items);
          navigate("/cart", { replace: true });
        }else if (data.account_status == "inactive") {
          navigate("/account-update-required", { replace: true });
        } else if (data.account_status == "unauthorized") {
          if (deleteStorage()) {
            navigate("/signin", { replace: true });
          }
        }
      } catch (error) {
        updateLoadingStatus(false);
      }
    }

    if (getStorage("accountID") != "" && urlParam != "") {
      if (
        customUploadedImage != null &&
        productDetails.productSizes.length > 0 &&
        selectedProductSize != ""
      ) {
        let productSize = selectedProductSize;
        if (productSize == "") {
          productSize = "nosize";
        }

        updateLoadingStatus(true);

        let fd = new FormData();
        fd.append("ACCOUNT_ID", getStorage("accountID"));
        fd.append("PRODUCT_ID", productDetails.productId);
        fd.append("PRODUCT_SIZE", productSize);
        fd.append("TOTAL_IMAGES", customUploadedImage.length);
        customUploadedImage.forEach((image, index) => {
          fd.append(`PRODUCT_CUSTOM_IMAGES_${index}`, image);
        });

        requestAPI(API_ACCESS_URL, fd);
      }
    } else {
      navigate("/signin", { replace: true });
    }
  };

  useEffect(() => {
    if (urlParam != "") {
      getProductDetails();
    }
  }, [productDetails.productImageList]);

  return (
    <div className="v-center app-bg">
      <div className="h-100vh w-100 ovf-scrl-y">
        <ToastDialog
          intentData={pageConst}
          updateState={updateToastDialogState}
        />
        <LoadingDialog intentData={pageConst} />
        <DialogV2 intentData={dialogV2} updateState={updateDialogV2} />

        <MainHeader intentData={mainHeader} />

        <div className="min-h-100vh">
          <div
            className={`col-view resp-width margin-0-auto ${
              !isDataLoaded ? "hide-v" : ""
            }`}
          >
            <div className="mg-b-50">
              <div className={`w-100 container-v-6`}>
                <div className={`w-100 container-v-6-holder pd-10 mg-t-10`}>
                  <div className="containerItem ovf-hidden bg-white">
                    <div className="containerItemImageHolder">
                      <Splide
                        options={{
                          perPage: 1,
                          gap: "1rem",
                        }}
                      >
                        {productDetails.productImageList.map((data, index) => (
                          <SplideSlide>
                            <ImageLoader
                              className="objectFitContain lazy-loading w-100 max-h-500px obj-f-contain"
                              src={data}
                              alt="product-image"
                              key={index}
                            />
                          </SplideSlide>
                        ))}
                      </Splide>
                    </div>

                    <div className="containerContentsHolder col-view">
                      <h1 className="ft-sz-18 ft-wgt-500">
                        {productDetails.productName}
                      </h1>

                      <div className="col-view pd-15-0">
                        <div className="row-view">
                          <span className="ft-sz-25 ft-wgt-600">
                            ₹{productDetails.productOfferedPrice}
                          </span>
                          <s
                            className={`ft-sz-15 mg-l-5 ${
                              productDetails.productOffer <= 0 ? "hide-v" : ""
                            }`}
                          >
                            ₹{productDetails.productPrice}
                          </s>
                          <span
                            className={`ft-sz-16 ft-wgt-500 cl-green mg-l-10 ${
                              Number(productDetails.productOffer) <= 0
                                ? "hide-v"
                                : ""
                            }`}
                          >
                            {productDetails.productOffer}% OFF
                          </span>
                        </div>

                        <span className="ft-sz-12 mg-t-5">
                          inclusive of all taxes
                        </span>
                      </div>

                      <p
                        className="mg-t-15"
                        dangerouslySetInnerHTML={{
                          __html: productDetails.productDescription,
                        }}
                      ></p>

                      <div
                        className={`col-view w-100 mg-t-25 ${
                          productDetails.productSizes == "" ? "hide-v" : ""
                        }`}
                      >
                        <div className="w-100 row-view sb-view">
                          <div className="ft-sz-14 ft-wgt-600">
                            Select Size{" "}
                            <span
                              className={`ft-sz-13 cl-red ${
                                selectedProductSize != "" ? "hide-v" : ""
                              }`}
                            >
                              (Please select size)
                            </span>
                          </div>
                          <div className="ft-sz-14">Size Guide</div>
                        </div>

                        <div className="w-100 row-view gap-0p5 flex-wrap mg-t-15">
                          {productDetails.productSizes != null
                            ? productDetails.productSizes.map((data, index) => (
                                <label
                                  className={`min-w-40-p max-w-120px v-center pd-10 br-5 ${
                                    selectedProductSize == data["product_size"]
                                      ? "cl-white bg-black"
                                      : "cl-back bg-grey"
                                  }`}
                                  onClick={() =>
                                    updateProductSizingPricing(
                                      data["product_size"]
                                    )
                                  }
                                >
                                  {data["product_size"]}
                                </label>
                              ))
                            : ""}
                        </div>
                      </div>

                      <div className="mg-t-30"></div>
                      <UploadShowImage
                        onImageUpdate={onImageUpdate}
                        showNewMessage={setErrorMessage}
                      />

                      <div className="row-view gap-0p5 flex-wrap mg-t-15">
                        {customUploadedImage != null &&
                          customUploadedImage.map((imageSrc, index) => (
                            <div key={index}>
                              <img
                                className="h-100px"
                                src={URL.createObjectURL(imageSrc)}
                                alt="icon"
                              />
                            </div>
                          ))}
                      </div>

                      <span className="ft-sz-12 cl-drk-red mg-t-15">
                        {errorMessage}
                      </span>

                      <div
                        className={`w-100 mg-t-20 h-50-p ft-sz-20 v-center br-5 cl-white bg-primary`}
                        onClick={() => addToBag()}
                      >
                        <span>Add to Cart</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer intentData={pageConst} />
      </div>
    </div>
  );
}

export default CustomizeProduct;
