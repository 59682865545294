import React from "react";
import { Link } from "react-router-dom";
import ImageLoader from "../other-components/ImageLoader";
import { WEBSITE_URL, openNewPage } from "../modals/Constants";

function ContainerV1Modal({ dataList }) {
  return (
    <>
      {dataList != null
        ? dataList.map((data, index) => (
            <Link
              key={index}
              className="productItemContainer col-view ovf-hidden bg-white"
              onClick={() =>
                openNewPage(WEBSITE_URL + "/p/" + data["product_slug"])
              }
            >
              <div className="productImageContainer">
                <ImageLoader
                  className="w-100 h-100 objectFitContain lazy-loading br-5"
                  src={data["product_image"]}
                  alt={data["product_name"]}
                />
              </div>

              <h3 className="productItemTitle w-calc-90 ovf-hidden mg-t-10">
                {data["product_name"]}
              </h3>
              <div className="row-view mg-t-10">
                <span className="cl-black ft-sz-16 ft-wgt-600">
                  ₹{data["product_offered_price"]}
                </span>
                <s
                  className={`cl-black ft-sz-13 mg-l-5 ${
                    Number(data["product_offer"]) <= 0 ? "hide-v" : ""
                  }`}
                >
                  ₹{data["product_price"]}
                </s>
                <span
                  className={`cl-green ft-sz-14 ft-wgt-500 mg-l-10 ${
                    Number(data["product_offer"]) <= 0 ? "hide-v" : ""
                  }`}
                >
                  {data["product_offer"]}% OFF
                </span>
              </div>

              <div
                className={`w-100 row-view mg-t-10 ${
                  data["product_tags"] == "" ? "hide-v" : ""
                }`}
              >
                <span className="cl-black pd-2-8 ft-sz-10 br-a-grey">
                  {data["product_tags"]}
                </span>
              </div>
            </Link>
          ))
        : ""}
    </>
  );
}

export default ContainerV1Modal;
