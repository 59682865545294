import React from 'react'
import '../../MainStyle.css';
import { Link } from "react-router-dom";

const DialogV3 = ({intentData,updateState}) => {

    return (
        <div className={`pos-fx pos-top-0 pos-rgt-0 w-100 h-100vh v-center z-i-1000 bg-l-black ${intentData.showDialog ? '' : 'hide-v'}`} onClick={()=>updateState(false,"close")}>
            <div className='dialogV2Container'>
                <div className='row-view sb-view'>
                  <span className='mg-t-10 ft-sz-17 ft-wgt-600'>{intentData.dialogTitle}</span>
                  <span className='v-center h-w-28 br-5 bg-l-red'>✕</span>
                </div>

                {intentData.addressList!=null ? intentData.addressList.map((data, index) => (
                    <div className="col-view pd-20-10 mg-t-20 br-5 br-a-l-grey">
                      <span className="ft-sz-16 ft-wgt-600">{data['address_fullname']}</span>
                      <span className="ft-sz-13 mg-t-5">{data['address_state']+','+data['address_area']}</span>
                        
                      <span className="ft-sz-13 mg-t-15">{data['address_city']+','+data['address_pincode']}</span>
                      <span className="ft-sz-13 mg-t-5">Contact Number: {data['address_mobile']}</span>
                        
                      <div className="row-view mg-t-20">
                        <Link className="txt-deco-n cl-blue pd-5 ft-sz-17" to={"/edit-address/"+data['address_id']}>Edit</Link>
                      </div>
                    </div>
                )) : ''}
                
                <div className={`w-100 h-50-p ft-sz-18 v-center mg-t-25 br-5 bg-primary`} onClick={()=>updateState(false,"checkout")}>
                  <span>Continue</span>
                </div>

                <div className={`w-100 v-center pd-10-15 mg-t-10 ft-sz-14 cl-blue`} onClick={()=>updateState(false,"addaddress")}>
                  <span>Add,Edit Address</span>
                </div>
            </div>
        </div>
    );
};

export default DialogV3;