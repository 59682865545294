import React, { Component } from "react";

const _loaded = {};
class ImageLoader extends Component {
  static defaultProps = {
    className: "",
    loadingClassName: "loading",
    loadedClassName: "loaded"
  };

  constructor(props, context) {
    super(props, context);
    this.state = { loaded: _loaded[props.src] };
  }

  onLoad = () => {
    _loaded[this.props.src] = true;
    this.setState(() => ({ loaded: true }));
  };

  render() {
    let { className, loadedClassName, loadingClassName, ...props } = this.props;
    className = `${className} ${this.state.loaded
      ? loadedClassName
      : loadingClassName}`;

    return <img {...props} className={className} onLoad={this.onLoad} />;
  }
}

export default ImageLoader;
