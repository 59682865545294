import React, { useEffect, useState, useRef } from "react";

const UploadShowImage = ({ onImageUpdate, showNewMessage }) => {
  // Create a reference to the hidden file input element
  const [filesLimit, setFilesLimit] = useState(5);
  const [maxFileSize, setMaxFileSize] = useState(2);
  const [images, setImages] = useState([]);
  const [imageURLS, setImageURLs] = useState([]);

  const hiddenFileInput = useRef(null);

  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };

  const handleChange = (event) => {
    let isReadyToUpload = true;
    let selectedFiles = event.target.files;

    if (selectedFiles.length > filesLimit) {
      isReadyToUpload = false;
      showNewMessage(`Sorry! you can add upto ${filesLimit} images!`);
    } else {
      for (let i = 0; i < selectedFiles.length; i++) {
        if (selectedFiles[i].size > maxFileSize * 1024 * 1024) {
          isReadyToUpload = false;
          showNewMessage(`Sorry! you can't upload more than ${maxFileSize}mb file!`);
          break;
        } else {
          isReadyToUpload = true;
        }
      }
    }

    if (isReadyToUpload) {
      setImages([...selectedFiles]);
    }
  };

  useEffect(() => {
    if (images.length < 1) return;
    const newImageUrls = [];
    images.forEach((image) => newImageUrls.push(image));
    setImageURLs(newImageUrls);
    onImageUpdate(newImageUrls);
  }, [images]);

  return (
    <>
      <div className="dragDropUploadContainer" onClick={handleClick}>
        <div className="col-view v-center dragDropUploadWrapper">
          <div className="col-view a-center">
            <img
              className="h-40-p"
              src={require("../media/icons/photo_icon.png")}
              alt="icon"
            />
            <span className="ft-sz-18 ft-wgt-600 mg-t-25">Upload Here</span>
            <span className="ft-sz-13 mg-t-10">
              *Kindly upload high-resolution images for improved product
              quality.
            </span>
          </div>
        </div>
      </div>

      <input
        type="file"
        multiple="multiple"
        accept="image/jpeg,image/png,image/jpg"
        onChange={handleChange}
        ref={hiddenFileInput}
        style={{ display: "none" }}
      />
    </>
  );
};

export default UploadShowImage;
