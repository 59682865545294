import React, { useState } from "react";
import { Link } from "react-router-dom";
import "../../MainStyle.css";
import general from "../css/General.module.css";

function AccountUpdateInfo() {

  return (
    <div className={[general.viewCenter, general.appBackground].join(" ")}>
      <div className="h-100vh w-100 ovf-scrl-y">
        <div className="w-100 col-view">
          <div className="v-center flex-d-col min-h-100vh resp-width margin-0-auto">
            <img
              src={require("../media/icons/user_icon.png")}
              className={[general.height70px]}
            />
            <h3 className="ft-sz-20 mg-t-10">Account Update Required!</h3>
            <span className="ft-sz-14 mg-t-5">
              Please update your profile & continue.
            </span>
            <Link
              className="txt-deco-n cl-blue ft-sz-18 ft-wgt-600 mg-t-15"
              to={"/update-account"}
            >
              Update Now
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AccountUpdateInfo;
