import React,{useState} from 'react'
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import './App.css';
import './MainStyle.css';
import Home from './components/screens/Home';
import Account from './components/screens/Account';
import ViewProduct from './components/screens/ViewProduct';
import Login from './components/screens/Login';
import ChangePassword from './components/screens/ChangePassword';
import Cart from './components/screens/Cart';
import Checkout from './components/screens/Checkout';
import Search from './components/screens/Search';
import UnderMaintenance from './components/screens/UnderMaintenance';
import OnBoard from './components/screens/OnBoard';
import AddAddress from './components/screens/AddAddress';
import AllAddresses from './components/screens/AllAddresses';
import OrderConfirmed from './components/screens/OrderConfirmed';
import AllOrders from './components/screens/AllOrders';
import EditAddress from './components/screens/EditAddress';
import ProductCategoryWise from './components/screens/ProductCategoryWise';
import OrderedDetails from './components/screens/OrderDetails';
import CancelOrder from './components/screens/CancelOrder';
import OtpVerification from './components/screens/OtpVerification';
import PasswordVerification from './components/screens/PasswordVerification';
import CustomizeProduct from './components/screens/CustomizeProduct';
import AccountUpdateInfo from './components/screens/AccountUpdateInfo';

function App() {

  return <>
  <Router>

    <Routes>
      <Route path='/home' element={<Home/>} />
      <Route path='p/:urlParam' element={<ViewProduct/>} />

      
      <Route path='/signin' element={<Login/>} />
      <Route path='/otp-verification' element={<OtpVerification/>} />
      <Route path='/password-verification' element={<PasswordVerification/>} />
      <Route path='/onboard' element={<OnBoard/>} />
      <Route path='/update-account' element={<OnBoard/>} />
      <Route path='/account' element={<Account/>} />
      <Route path='/account-update-required' element={<AccountUpdateInfo/>} />

      <Route path='customize/:urlParam' element={<CustomizeProduct/>} />
      
      <Route path='/addresses' element={<AllAddresses/>} />
      <Route path='/add-address' element={<AddAddress/>} />
      <Route path='edit-address/:urlParam' element={<EditAddress/>} />

      <Route path='/orders' element={<AllOrders/>} />
      <Route path='orders/:urlParam' element={<OrderedDetails/>} />
      <Route path='cancel-order/:urlParam' element={<CancelOrder/>} />

      <Route path='/change-password' element={<ChangePassword/>} />
      <Route path='/checkout' element={<Checkout/>} /> 
      <Route path='/cart' element={<Cart/>} /> 
      <Route path='/order-confirmed' element={<OrderConfirmed/>} /> 

      <Route path='search/:urlParam' element={<Search/>} />
      <Route path='c/:urlParam' element={<ProductCategoryWise/>} />

      <Route path='/um' element={<UnderMaintenance/>} />

      {/* default page */}
      <Route path='/' element={<Home/>} />
    </Routes>

  </Router>
  </>;
}

export default App;
