import React, { useState, useRef, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import ToastDialog from "../dialogs/ToastDialog";
import {
  WEBSITE_URL,
  API_ACCESS_URL,
  getURLParam,
  openNewPage,
  getHostName,
} from "../modals/Constants";
import MainHeader from "../other-components/MainHeader";
import { getStorage, setStorage } from "../modals/Storage";
import LoadingDialog from "../dialogs/LoadingDialog";
import DialogV2 from "../dialogs/DialogV2";
import DialogV3 from "../dialogs/DialogV3";

function Cart() {
  const navigate = useNavigate();

  const [pageConst, setConstants] = useState({
    showHeadSearch: true,
    totalMRP: 0,
    totalDiscount: 0,
    totalDeliveryFee: 0,
    subTotalCost: 0,
    totalItems: -1,
    toastMessage: "",
    toastDialogShow: false,
    isLoadingShow: false,
    isShowFooter: true,
    productList: [],
  });

  const [mainHeader, setMainHeader] = useState({
    showHeader: true,
    headerType: 2,
  });

  const [dialogV2, setDialogV2] = useState({
    showDialog: false,
    dialogTitle: "Select Size!",
    dialogProductId: "",
    dialogExtra: "",
    sizesList: [],
  });

  const [dialogV3, setDialogV3] = useState({
    showDialog: false,
    dialogTitle: "Select Delivery Address!",
    dialogDetails: "",
    addressList: [],
  });

  const updateSizes = (productBagId, data) => {
    setDialogV2((previousState) => {
      return { ...previousState, dialogProductId: productBagId };
    });

    if (data == "numArray") {
      const numArray = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"];

      setDialogV2((previousState) => {
        return { ...previousState, dialogTitle: "Select Quantity!" };
      });

      setDialogV2((previousState) => {
        return { ...previousState, sizesList: numArray };
      });

      setDialogV2((previousState) => {
        return { ...previousState, dialogExtra: "qty" };
      });
    } else {
      setDialogV2((previousState) => {
        return { ...previousState, dialogTitle: "Select Size!" };
      });

      setDialogV2((previousState) => {
        return { ...previousState, sizesList: data };
      });

      setDialogV2((previousState) => {
        return { ...previousState, dialogExtra: "size" };
      });
    }

    updateDialogV2(true, "");
  };

  const updateDialogV2 = (data, type) => {
    setDialogV2((previousState) => {
      return { ...previousState, showDialog: data };
    });

    if (type != "close" && type != "") {
      if (dialogV2.dialogExtra == "size") {
        updateBagProducts(dialogV2.dialogProductId, type, "none");
      } else if (dialogV2.dialogExtra == "qty") {
        updateBagProducts(dialogV2.dialogProductId, "none", type);
      }
    }
  };

  const updateDialogV3 = (data, type) => {
    setDialogV3((previousState) => {
      return { ...previousState, showDialog: data };
    });

    if (type == "checkout") {
      navigate("/checkout", { replace: false });
    }

    if (type == "addaddress") {
      navigate("/addresses", { replace: false });
    }
  };

  const updateAddressArray = (data) => {
    setDialogV3((previousState) => {
      return { ...previousState, addressList: data };
    });
  };

  const updateLoadingStatus = (data) => {
    setConstants((previousState) => {
      return { ...previousState, isLoadingShow: data };
    });
  };

  const updateToastDialogState = (data, msg) => {
    setConstants((previousState) => {
      return { ...previousState, toastDialogShow: data };
    });

    setConstants((previousState) => {
      return { ...previousState, toastMessage: msg };
    });
  };

  function updateBagProducts(productBagId, size, qty) {
    async function requestAPI(url, formData) {
      try {
        const res = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            route: "route-update-bag-products",
            // 'AuthToken': generateAuthToken(pageConst.inMobileNum,ip),
          },
          body: JSON.stringify(formData),
        });

        const data = await res.json();
        updateLoadingStatus(false);

        if (data.status_code == "success") {
          loadMyBag(true);
        }
      } catch (error) {
        updateLoadingStatus(false);
      }
    }

    if (productBagId != "" && size != "" && qty != "") {
      updateLoadingStatus(true);
      const formData = {
        ACCOUNT_ID: getStorage("accountID"),
        AUTH_SECRET: getStorage("accountSecret"),
        PRODUCT_BAG_ID: productBagId,
        PRODUCT_SIZE: size,
        PRODUCT_QTY: qty,
      };
      requestAPI(API_ACCESS_URL, formData);
    }
  }

  const updateUIItems = (data) => {
    let tempData = [];

    for (let i = 0; i < data.length; i++) {
      tempData.push(
        <div className="h-fit-content br-5">
          <div className=" row-view al-items-flex-start pd-15">
            <img
              className="w-100-p br-5"
              src={data[i]["product_image"]}
              onClick={() =>
                openNewPage(getHostName() + "/p/" + data[i]["product_slug"])
              }
            />

            <div className="col-view w-100 ovf-hidden mg-l-15">
              <span className="productItemTitle ovf-hidden">
                {data[i]["product_name"]}
              </span>

              <div className="row-view mg-t-10">
                <h3 className="ft-sz-16">
                  ₹{data[i]["product_offered_price"]}
                </h3>
                <s
                  className={`ft-sz-14 mg-l-5 ${
                    Number(data[i]["product_offer"]) <= 0 ? "hide-v" : ""
                  }`}
                >
                  ₹{data[i]["product_price"]}
                </s>
              </div>
              <span
                className={`ft-sz-12 cl-green mg-t-10 ${
                  Number(data[i]["product_offer"]) <= 0 ? "hide-v" : ""
                }`}
              >
                You saved ₹
                {Number(data[i]["product_price"]) -
                  Number(data[i]["product_offered_price"])}
              </span>

              <div className="row-view mg-t-20 gap-0p5">
                <span
                  className={`v-center ft-sz-14 pd-10-15 br-5 bg-l-blue ${
                    data[i]["product_size"] == "nosize" ? "hide-v" : ""
                  }`}
                  onClick={() =>
                    updateSizes(
                      data[i]["product_bag_id"],
                      data[i]["product_sizes"]
                    )
                  }
                >
                  Size: {data[i]["product_size"]}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="1em"
                    height="1em"
                    fill="none"
                    viewBox="0 0 24 24"
                    style={{ "font-size": "16px" }}
                    stroke="#207bb4"
                    class="icon_override__2wEoD"
                  >
                    <path
                      stroke="#000"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="m5 8.5 7 7 7-7"
                    ></path>
                  </svg>
                </span>

                <span
                  className="v-center ft-sz-14 pd-10-15 br-5 bg-l-blue"
                  onClick={() =>
                    updateSizes(data[i]["product_bag_id"], "numArray")
                  }
                >
                  Qty: {data[i]["product_quantity"]}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="1em"
                    height="1em"
                    fill="none"
                    viewBox="0 0 24 24"
                    style={{ "font-size": "16px" }}
                    stroke="#207bb4"
                    class="icon_override__2wEoD"
                  >
                    <path
                      stroke="#000"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="m5 8.5 7 7 7-7"
                    ></path>
                  </svg>
                </span>
              </div>
            </div>
          </div>

          <div
            className="pd-15"
            onClick={() => removeProduct(data[i]["product_bag_id"])}
          >
            <div className="v-center pd-10-15 cl-red br-5 bg-l-red cursor-pointer">
              Remove
            </div>
          </div>
        </div>
      );
    }

    setConstants((previousState) => {
      return { ...previousState, productList: tempData };
    });
  };

  function loadMyBag(forcedLoad) {
    async function requestAPI(url, formData) {
      try {
        const res = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            route: "route-bag-products",
          },
          body: JSON.stringify(formData),
        });

        const data = await res.json();
        updateLoadingStatus(false);

        if ((data.status_code = "success")) {
          setStorage("accountBagItems", data.total_bag_items);

          setConstants((previousState) => {
            return { ...previousState, totalMRP: data.total_mrp };
          });

          setConstants((previousState) => {
            return { ...previousState, totalDiscount: data.total_discount };
          });

          setConstants((previousState) => {
            return {
              ...previousState,
              totalDeliveryFee: data.total_delivery_fee,
            };
          });

          setConstants((previousState) => {
            return { ...previousState, subTotalCost: data.subtotal_cost };
          });

          if (data.data.length > 0) {
            setConstants((previousState) => {
              return { ...previousState, totalItems: data.data.length };
            });
          } else {
            setConstants((previousState) => {
              return { ...previousState, totalItems: 0 };
            });
          }

          updateUIItems(data.data);
          updateAddressArray(data.addressArr);
        }
      } catch (error) {
        updateLoadingStatus(false);
      }
    }

    if (!pageConst.dataLoaded || forcedLoad) {
      updateLoadingStatus(true);

      setConstants((previousState) => {
        return { ...previousState, dataLoaded: true };
      });
      const formData = {
        ACCOUNT_ID: getStorage("accountID"),
        AUTH_SECRET: getStorage("accountSecret"),
        BAG_PRODUCTS: "",
      };
      requestAPI(API_ACCESS_URL, formData);
    }
  }

  const proceedCheckout = () => {
    if (dialogV3.addressList.length > 0) {
      updateDialogV3(true, "show");
    } else if (pageConst.totalMRP >= 500) {
      navigate("/addresses", { replace: false });
    }
  };

  const removeProduct = (productBagId) => {
    async function requestAPI(url, formData) {
      try {
        const res = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            route: "route-remove-from-bag",
            // 'AuthToken': generateAuthToken(pageConst.inMobileNum,ip),
          },
          body: JSON.stringify(formData),
        });

        const data = await res.json();
        updateLoadingStatus(false);

        if ((data.status_code = "success")) {
          loadMyBag(true);
        }
      } catch (error) {
        updateLoadingStatus(false);
      }
    }

    if (productBagId != "") {
      updateLoadingStatus(true);
      const formData = {
        ACCOUNT_ID: getStorage("accountID"),
        AUTH_SECRET: getStorage("accountSecret"),
        PRODUCT_BAG_ID: productBagId,
      };
      requestAPI(API_ACCESS_URL, formData);
    }
  };

  useEffect(() => {
    loadMyBag(false);
  }, [pageConst.totalMRP, pageConst.totalDiscount]);

  return (
    <div className="v-center app-bg">
      <div className="h-100vh w-100 ovf-scrl-y">
        <ToastDialog
          intentData={pageConst}
          updateState={updateToastDialogState}
        />
        <LoadingDialog intentData={pageConst} />
        <DialogV2 intentData={dialogV2} updateState={updateDialogV2} />
        <DialogV3 intentData={dialogV3} updateState={updateDialogV3} />

        <div className="w-100 col-view">
          <MainHeader intentData={mainHeader} />

          <div className="col-view min-h-100vh resp-width margin-0-auto">
            <div
              className={`pd-0-15 col-view mg-t-15 pd-b-100 ${
                pageConst.totalItems <= 0 ? "hide-v" : ""
              }`}
            >
              <h3>My Bag ({pageConst.totalItems} item)</h3>

              <div className="cartItemsContainer w-100 mg-t-55">
                <div className="cartItemsWrapper col-view">
                  <div className="v-center pd-10-15 br-5 bg-l-primary">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="1em"
                      height="1em"
                      fill="none"
                      viewBox="0 0 16 16"
                      stroke="none"
                      className="ft-sz-16"
                    >
                      <path
                        stroke="#292D35"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M10.357 5.287h2.092a.388.388 0 0 1 .364.246L13.5 7.25M2.5 8.43h7.857"
                      ></path>
                      <path
                        stroke="#292D35"
                        stroke-miterlimit="10"
                        d="M10.946 11.965a1.179 1.179 0 1 0 0-2.357 1.179 1.179 0 0 0 0 2.357ZM5.054 11.965a1.179 1.179 0 1 0 0-2.358 1.179 1.179 0 0 0 0 2.358Z"
                      ></path>
                      <path
                        stroke="#292D35"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M9.768 10.787H6.232M3.875 10.786h-.982a.393.393 0 0 1-.393-.393v-5.5a.393.393 0 0 1 .393-.393h7.464v5.264M10.357 7.25H13.5v3.143a.393.393 0 0 1-.393.393h-.982"
                      ></path>
                    </svg>
                    <span className="ft-sz-13 mg-l-10">
                      {pageConst.totalDeliveryFee > 0
                        ? "Shop For 100rs more to get FREE Delivery"
                        : "Yay! You get FREE delivery on this order"}
                    </span>
                  </div>

                  {pageConst.productList}
                </div>

                <div className="cartSummaryWrapper h-fit-content col-view mg-l-15 br-5">
                  <div className="col-view">
                    <span className="ft-sz-14 ft-wgt-600 pd-10">
                      Payment Details
                    </span>

                    <div className="row-view sb-view ft-sz-14 pd-10">
                      <span>Total MRP (Inc. of taxes)</span>
                      <span>₹{pageConst.totalMRP}</span>
                    </div>

                    <div className="row-view sb-view ft-sz-14 pd-10">
                      <span>Delivery Fee</span>
                      <span>
                        {pageConst.totalDeliveryFee > 0
                          ? "₹" + pageConst.totalDeliveryFee
                          : "Free"}
                      </span>
                    </div>

                    <div className="row-view sb-view ft-sz-14 pd-10">
                      <span>Bag Discount</span>
                      <span>-₹{pageConst.totalDiscount}</span>
                    </div>

                    <div className="line-hv-grey mg-t-15"></div>

                    <div className="row-view sb-view ft-sz-14 pd-15">
                      <span className="ft-sz-17 ft-wgt-600">Sub Total</span>
                      <span className="ft-sz-17 ft-wgt-600">
                        ₹{pageConst.subTotalCost}
                      </span>
                    </div>
                  </div>

                  <div className="pd-15" onClick={() => proceedCheckout()}>
                    <div className="v-center pd-10-15 ft-wgt-600 br-5 bg-primary">
                      Proceed
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              className={`col-view v-center mg-t-55 ${
                pageConst.totalItems == 0 ? "" : "hide-v"
              }`}
            >
              <img
                className="h-180-p"
                src="https://images.bewakoof.com/images/doodles/empty-cart-page-doodle.png"
              />
              <label className="ft-sz-18 ft-wgt-600 mg-t-15">
                Nothing in tha bag
              </label>
              <Link
                className="txt-deco-n ft-sz-16 cl-primary pd-10-15 mg-t-5"
                to={"/"}
              >
                Continue Shopping
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Cart;
