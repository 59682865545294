import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import ToastDialog from "../dialogs/ToastDialog";
import {
  WEBSITE_URL,
  API_ACCESS_URL,
  getFullURL,
  getHostName,
  openNewPage,
} from "../modals/Constants";
import MainHeader from "../other-components/MainHeader";
import { getStorage } from "../modals/Storage";
import LoadingDialog from "../dialogs/LoadingDialog";

function OrderDetails() {
  const navigate = useNavigate();
  let { urlParam } = useParams();

  const [pageConst, setConstants] = useState({
    showHeadSearch: true,
    totalItems: -1,
    toastMessage: "",
    toastDialogShow: false,
    isLoadingShow: false,
    isShowFooter: true,
    productList: [],
  });

  const [mainHeader, setMainHeader] = useState({
    showHeader: true,
    headerType: 2,
  });

  const updateLoadingStatus = (data) => {
    setConstants((previousState) => {
      return { ...previousState, isLoadingShow: data };
    });
  };

  const updateToastDialogState = (data, msg) => {
    setConstants((previousState) => {
      return { ...previousState, toastDialogShow: data };
    });

    setConstants((previousState) => {
      return { ...previousState, toastMessage: msg };
    });
  };

  const updateUIItems = (data) => {
    let tempData = [];

    for (let i = 0; i < data.length; i++) {
      tempData.push(
        <>
          <div className="txt-deco-n h-fit-content cl-black br-5">
            <div className="row-view pd-15">
              <div className="col-view w-100 ovf-hidden mg-l-10">
                <span className="ft-sz-16 ft-wgt-600">
                  Order Number: {data[i]["product_ordered_id"]}
                </span>
                <span className="ft-sz-13 mg-t-5">
                  Order Placed: {data[i]["product_order_datetime"]}
                </span>
              </div>
            </div>

            <div className="line-hv-grey"></div>

            <Link
              className="txt-deco-n row-view al-items-flex-start pd-15 cl-black"
              target="_blank"
              to={getHostName() + "/p/" + data[i]["product_slug"]}
            >
              <img
                className="productItemImage br-5"
                src={data[i]["product_image"]}
              />

              <div className="col-view w-100 ovf-hidden mg-l-10">
                <span className="productItemTitle ovf-hidden">
                  {data[i]["product_name"]}
                </span>

                <span className="ft-sz-14 mg-t-20">
                  Size: {data[i]["product_size"]}
                </span>

                <span className="ft-sz-14 mg-t-10">
                  Price: ₹{data[i]["product_price"]}
                </span>
              </div>
            </Link>
          </div>

          <div className="txt-deco-n col-view h-fit-content cl-black pd-15 br-5">
            <div className="row-view pd-15">
              {data[i]["product_order_status"] == "delivered" ? (
                <img
                  className="h-w-26 br-5"
                  src={require("../media/icons/check_icon_selected.png")}
                />
              ) : data[i]["product_order_status"] == "cancelled" ? (
                <img
                  className="h-w-26 br-5"
                  src={require("../media/icons/cross_icon_dark.png")}
                />
              ) : (
                <img
                  className="h-w-26 br-5"
                  src={require("../media/icons/check_icon_dark.png")}
                />
              )}

              <div className="col-view w-100 ovf-hidden mg-l-10">
                <span className="txt-capitalize ft-sz-16 ft-wgt-600">
                  order {data[i]["product_order_status"]}
                </span>
                <span className="ft-sz-13 mg-t-5">
                  Order Placed: {data[i]["product_order_datetime"]}
                </span>
              </div>
            </div>

            <div className="line-hv-grey"></div>

            <Link
              className={`txt-deco-n row-view sb-view mg-t-15 ${
                data[i]["product_order_status"] == "confirmed" ? "" : "hide-v"
              }`}
              to={"/cancel-order/" + data[i]["product_ordered_id"]}
            >
              <div className="pd-10-15 cl-red br-5 bg-l-red">Cancel</div>
            </Link>
          </div>

          <div className="txt-deco-n col-view h-fit-content cl-black pd-15 br-5">
            <span className="ft-sz-16 ft-wgt-600">Shipping Address</span>

            <span className="ft-sz-15 ft-wgt-600 mg-t-15">
              {data[i]["product_order_address"][0]}
            </span>
            <span className="ft-sz-13 mg-t-5">
              {data[i]["product_order_address"][5]}
            </span>

            <span className="ft-sz-13 mg-t-15">
              {data[i]["product_order_address"][3] +
                "," +
                data[i]["product_order_address"][4]}
            </span>
            <span className="ft-sz-13 mg-t-5">
              Contact Number: {data[i]["product_order_address"][1]}
            </span>
          </div>
        </>
      );
    }

    setConstants((previousState) => {
      return { ...previousState, productList: tempData };
    });
  };

  function getProductDetails(forcedLoad) {
    async function requestAPI(url, formData) {
      try {
        const res = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            route: "route-ordered-product-details",
            // 'AuthToken': generateAuthToken(pageConst.inMobileNum,ip),
          },
          body: JSON.stringify(formData),
        });

        const data = await res.json();
        updateLoadingStatus(false);

        if ((data.status_code = "success")) {
          console.log(data.data);

          if (data.data.length > 0) {
            setConstants((previousState) => {
              return { ...previousState, totalItems: data.data.length };
            });
          } else {
            setConstants((previousState) => {
              return { ...previousState, totalItems: 0 };
            });
          }

          // calculateTotalCost(data.data);
          updateUIItems(data.data);
        }
      } catch (error) {
        updateLoadingStatus(false);
      }
    }

    if (!pageConst.dataLoaded || forcedLoad) {
      updateLoadingStatus(true);

      setConstants((previousState) => {
        return { ...previousState, dataLoaded: true };
      });
      const formData = {
        ACCOUNT_ID: getStorage("accountID"),
        ORDER_ID: urlParam,
      };
      requestAPI(API_ACCESS_URL, formData);
    }
  }

  useEffect(() => {
    if (getStorage("accountID") == "" || getStorage("accountSecret") == "") {
      navigate("/signin", { replace: true });
    } else {
      getProductDetails(false);
    }
  }, [pageConst.totalMRP, pageConst.totalDiscount]);

  return (
    <div className="v-center app-bg">
      <div className="h-100vh w-100 ovf-scrl-y">
        <ToastDialog
          intentData={pageConst}
          updateState={updateToastDialogState}
        />
        <LoadingDialog intentData={pageConst} />

        <div className="w-100 col-view">
          <MainHeader intentData={mainHeader} />

          <div className="col-view min-h-100vh resp-width margin-0-auto">
            <div
              className={`pd-0-15 col-view mg-t-15 pd-b-100 ${
                pageConst.totalItems == 0 ? "hide-v" : ""
              }`}
            >
              <div className="orderItemsContainer w-100">
                <div className="orderItemsWrapper col-view">
                  {pageConst.productList}
                </div>
              </div>
            </div>

            <div
              className={`col-view v-center mg-t-55 ${
                pageConst.totalItems == 0 ? "" : "hide-v"
              }`}
            >
              <img
                className="h-180-p"
                src="https://images.bewakoof.com/images/doodles/empty-cart-page-doodle.png"
              />
              <label className="ft-sz-18 ft-wgt-600 mg-t-15">
                Nothing in tha bag
              </label>
              <Link
                className="txt-deco-n ft-sz-16 cl-primary pd-10-15 mg-t-5"
                to={"/"}
              >
                Continue Shopping
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OrderDetails;
