import React from 'react'
import { Link } from "react-router-dom";
import styles from '../DialogZoomImage/Styles.module.css';

const DialogZoomImage = ({intentData,updateState}) => {

    return (
        <div className={[styles.dialogView, `${intentData.showDialog ? '' : styles.dialogViewHide}`].join(' ')} >
            <div className={[styles.dialogContainer]}>
                <div className={[styles.dismissBtn]} onClick={() => updateState(false)}>
                  <span className='v-center h-w-28 br-5 bg-l-red'>✕</span>
                </div>

               <img src={intentData.dialogImage} alt='image' />
            </div>
        </div>
    );
};

export default DialogZoomImage;