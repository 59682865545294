import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams  } from "react-router-dom";
import ToastDialog from "../dialogs/ToastDialog";
import { WEBSITE_URL,API_ACCESS_URL } from "../modals/Constants";
import MainHeader from "../other-components/MainHeader";
import { getStorage } from "../modals/Storage";
import LoadingDialog from "../dialogs/LoadingDialog";

function CancelOrder() {
  const navigate = useNavigate();
  let { urlParam } = useParams();

  const [calcelReason, setCancelReason] = useState("");

  const [pageConst, setConstants] = useState({
    showHeadSearch: true,
    toastMessage: "",
    toastDialogShow: false,
    isLoadingShow: false,
    isShowFooter: true,
  });

  const [mainHeader, setMainHeader] = useState({
    showHeader: true,
    headerType: 2
  });

  const updateLoadingStatus = (data) => {
    setConstants(previousState => {
        return { ...previousState, isLoadingShow: data }
    });
  }

  const updateToastDialogState = (data, msg) => {
    setConstants((previousState) => {
      return { ...previousState, toastDialogShow: data };
    });

    setConstants((previousState) => {
      return { ...previousState, toastMessage: msg };
    });
  };

  const cancelOrder = () => {
    async function requestAPI(url,formData) {
      try {
        const res = await fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'route': 'route-cancel-order',
            // 'AuthToken': generateAuthToken(pageConst.inMobileNum,ip),
          },
          body: JSON.stringify(formData),
        });
        
        const data = await res.json();
        updateLoadingStatus(false);

        if(data.status_code="success"){
          navigate('/orders', { replace: true });
        }
      } catch (error) {
        updateLoadingStatus(false);
      }
    };

    if(calcelReason!=""){
      updateLoadingStatus(true);
      const formData = { ACCOUNT_ID: getStorage("accountID"), CANCEL_REASON: calcelReason, ORDER_ID: urlParam };
      requestAPI(API_ACCESS_URL, formData);
    }
  }

  return (
    <div className="v-center app-bg">
      <div className="h-100vh w-100 ovf-scrl-y">
        <ToastDialog intentData={pageConst} updateState={updateToastDialogState} />
        <LoadingDialog intentData={pageConst}/>

        <div className="w-100 col-view">
          <MainHeader intentData={mainHeader}/>

          <div className="col-view min-h-100vh resp-width mg-t-70 margin-0-auto">
            
            <div className={`pd-0-15 col-view mg-t-15 pd-b-100 ${pageConst.totalItems==0 ? 'hide-v' : ''}`}>
                <div className="col-view pd-15 br-5 br-a-grey">
                  <span className="ft-sz-16 ft-wgt-600">Select reason for cancellation*</span>

                  <div className="mg-t-20">
                    <input type="radio" id="reason1" name="cancel_reason" value="Want to order a different product" onChange={(e) => setCancelReason(e.target.value)} />
                    <label htmlFor="reason1" className="mg-l-10">Want to order a different product</label>
                  </div>

                  <div className="mg-t-15">
                    <input type="radio" id="reason2" name="cancel_reason" value="Do not want product anymore" onChange={(e) => setCancelReason(e.target.value)} />
                    <label htmlFor="reason2" className="mg-l-10">Do not want product anymore</label>
                  </div>

                  <div className="mg-t-15">
                    <input type="radio" id="reason3" name="cancel_reason" value="Duplicate order" onChange={(e) => setCancelReason(e.target.value)} />
                    <label htmlFor="reason3" className="mg-l-10">Duplicate order</label>
                  </div>

                  <div className="mg-t-15">
                    <input type="radio" id="reason4" name="cancel_reason" value="Ordered by mistake" onChange={(e) => setCancelReason(e.target.value)} />
                    <label htmlFor="reason4" className="mg-l-10">Ordered by mistake</label>
                  </div>

                  <div className="mg-t-15">
                    <input type="radio" id="reason5" name="cancel_reason" value="Others" onChange={(e) => setCancelReason(e.target.value)} />
                    <label htmlFor="reason5" className="mg-l-10">Others</label>
                  </div>

                  <div className="row-view sb-view mg-t-30" onClick={() => cancelOrder()}>
                    <div className="pd-10-15 cl-red br-5 bg-l-red">Cancel Order</div>
                  </div>
                </div>
            </div>

          </div>

        </div>
        
      </div>
    </div>
  );
}

export default CancelOrder;