import React from "react";
import { Link } from "react-router-dom";
import ImageLoader from "../other-components/ImageLoader";

function ContainerV2Modal({ dataList }) {
  return (
    <>
      {dataList != null
        ? dataList.map((data, index) => (
            <Link
              className="txt-deco-n br-10 bg-white"
              to={"/viewproduct?product=123"}
            >
              <ImageLoader
                className="lazy-loading w-100 res-h-250-p obj-f-contain br-5"
                src={data["container_item_image"]}
              />
            </Link>
          ))
        : ""}
    </>
  );
}

export default ContainerV2Modal;
