import React, { useState } from "react";
import { Link, useNavigate, useParams  } from "react-router-dom";
import MainHeader from "../other-components/MainHeader";
import '../../MainStyle.css';
import { Player } from '@lottiefiles/react-lottie-player';

function OrderConfirmed() {
  const navigate = useNavigate();

  const [pageConst, setConstants] = useState({
    showHeadSearch: true,
    productName: "",
    productDescription: ""
  });

  const [mainHeader, setMainHeader] = useState({
    showHeader: true,
    headerType: 2
  });

  return (
    <div className="v-center app-bg">
      <div className="h-100vh w-100 ovf-scrl-y">

        <div className="w-100 col-view">
          <MainHeader intentData={mainHeader}/>

          <div className="v-center flex-d-col min-h-100vh resp-width margin-0-auto">
            <Player src={require("../media/animations/order_confirmed_anim.json")} className="player w-125-p" loop autoplay />
            <h3 className="ft-sz-20 mg-t-10">Order Confirmed!</h3>
            <span className="ft-sz-14 mg-t-5">Thankyou for shopping!</span>
            <Link className="txt-deco-n cl-blue ft-sz-13 ft-wgt-600 mg-t-15" to={"/orders"}>ORDER DETAILS</Link>
          </div>

        </div>
        
      </div>
    </div>
  );
}

export default OrderConfirmed;