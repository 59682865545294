import React from 'react';
import { Link } from 'react-router-dom';
import ImageLoader from "../other-components/ImageLoader";

function ContainerV3Modal({dataList}) {

  return (
    <>
      {dataList!=null ? dataList.map((data, index) => (
        <Link key={index} to={"c/"+data['container_item_slug']}>
          <ImageLoader className='lazy-loading' src={data['container_item_image']} alt={data['container_title']} />
        </Link>
      )) : ''}
    </>
  );
}

export default ContainerV3Modal;