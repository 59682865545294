import React, { useState, useRef, useEffect } from "react";
import { Link, useNavigate, useParams  } from "react-router-dom";
import ToastDialog from "../dialogs/ToastDialog";
import { WEBSITE_URL,API_ACCESS_URL } from "../modals/Constants";
import MainHeader from "../other-components/MainHeader";
import { getStorage } from "../modals/Storage";
import LoadingDialog from "../dialogs/LoadingDialog";

function AllOrders() {
  const navigate = useNavigate();


  const [pageConst, setConstants] = useState({
    showHeadSearch: true,
    totalItems: -1,
    toastMessage: "",
    toastDialogShow: false,
    isLoadingShow: false,
    isShowFooter: true,
    productList: [],
  });

  const [mainHeader, setMainHeader] = useState({
    showHeader: true,
    headerType: 2
  });

  const updateLoadingStatus = (data) => {
    setConstants(previousState => {
        return { ...previousState, isLoadingShow: data }
    });
  }

  const updateToastDialogState = (data, msg) => {
    setConstants((previousState) => {
      return { ...previousState, toastDialogShow: data };
    });

    setConstants((previousState) => {
      return { ...previousState, toastMessage: msg };
    });
  };

  const updateUIItems = (data) => {
    let tempData = [];

    for (let i = 0; i < data.length; i++) {
      tempData.push(
      <Link className="txt-deco-n h-fit-content cl-black br-5" to={"/orders/"+data[i]['product_ordered_id']}>
        <div className="row-view sb-view pd-15">
          <div className="row-view">
            {data[i]['product_order_status']=='delivered' ? <img className="h-w-26 br-5" src={require("../media/icons/check_icon_selected.png")} /> : data[i]['product_order_status']=='cancelled' ?
            <img className="h-w-26 br-5" src={require("../media/icons/cross_icon_dark.png")} /> : <img className="h-w-26 br-5" src={require("../media/icons/check_icon_dark.png")} /> }

            <div className="col-view w-100 ovf-hidden mg-l-10">
              <span className="txt-capitalize ft-sz-16 ft-wgt-600">order {data[i]['product_order_status']}</span>
              <span className="ft-sz-13 mg-t-5">Order Placed: {data[i]['product_order_datetime']}</span>
            </div>
          </div>

          <svg viewBox="-19.04 0 75.804 75.804" xmlns="http://www.w3.org/2000/svg" fill="#fa3c09" className="h-w-12 mg-l-10"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <g id="Group_65" data-name="Group 65" transform="translate(-831.568 -384.448)"> <path id="Path_57" data-name="Path 57" d="M833.068,460.252a1.5,1.5,0,0,1-1.061-2.561l33.557-33.56a2.53,2.53,0,0,0,0-3.564l-33.557-33.558a1.5,1.5,0,0,1,2.122-2.121l33.556,33.558a5.53,5.53,0,0,1,0,7.807l-33.557,33.56A1.5,1.5,0,0,1,833.068,460.252Z" fill="#0c2c67"></path> </g> </g></svg>
        </div>

        <div className="line-hv-grey"></div>

        <div className="row-view al-items-flex-start pd-15">
          <img className="productItemImage br-5" src={data[i]['product_image']} />

          <div className="col-view w-100 ovf-hidden mg-l-10">
            <span className="productItemTitle ovf-hidden">{data[i]['product_name']}</span>

            <span className={`ft-sz-14 mg-t-20 ${data[i]['product_size']=='nosize' ? 'hide-v' : ''}`}>
              Size: {data[i]['product_size']}
            </span>

            <span className="ft-sz-14 mg-t-10">
              Price: ₹{data[i]['product_price']}
            </span>
          </div>
        </div>
      </Link>)
    };

    setConstants((previousState) => {
      return { ...previousState, productList: tempData };
    });
  };

  function loadMyOrders(forcedLoad){
    async function requestAPI(url,formData) {
      try {
        const res = await fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'route': 'route-ordered-products',
            // 'AuthToken': generateAuthToken(pageConst.inMobileNum,ip),
          },
          body: JSON.stringify(formData),
        });
        
        const data = await res.json();
        updateLoadingStatus(false);

        if(data.status_code="success"){
          console.log(data.data);

          if(data.data.length > 0){
            setConstants((previousState) => {
              return { ...previousState, totalItems: data.data.length };
            });
          }else{
            setConstants((previousState) => {
              return { ...previousState, totalItems: 0 };
            });
          }
          

          // calculateTotalCost(data.data);
          updateUIItems(data.data);
          
        }
      } catch (error) {
        updateLoadingStatus(false);
      }
    };

    if((!pageConst.dataLoaded || forcedLoad)){
      updateLoadingStatus(true);

      setConstants(previousState => {
        return { ...previousState, dataLoaded: true }
      });
      const formData = { ACCOUNT_ID: getStorage("accountID"), AUTH_SECRET: getStorage("accountSecret"), BAG_PRODUCTS: ""};
      requestAPI(API_ACCESS_URL, formData);
    }
  }

  const removeProduct = (productBagId) =>{

    async function requestAPI(url,formData) {
      try {
        const res = await fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'route': 'route-remove-from-bag',
            // 'AuthToken': generateAuthToken(pageConst.inMobileNum,ip),
          },
          body: JSON.stringify(formData),
        });
        
        const data = await res.json();
        updateLoadingStatus(false);

        if(data.status_code="success"){
          loadMyOrders(true);
        }
      } catch (error) {
        updateLoadingStatus(false);
      }
    }

    if((productBagId!="")){
      updateLoadingStatus(true);
      const formData = { ACCOUNT_ID: getStorage("accountID"), AUTH_SECRET: getStorage("accountSecret"), PRODUCT_BAG_ID: productBagId};
      requestAPI(API_ACCESS_URL, formData);
    }
  }

  useEffect(() => {
    if(getStorage("accountID")=='' || getStorage("accountSecret")==''){
      navigate('/signin', { replace: true });
    }else{
      loadMyOrders(false);
    }
  }, [pageConst.totalMRP,pageConst.totalDiscount]);

  return (
    <div className="v-center app-bg">
      <div className="h-100vh w-100 ovf-scrl-y">
        <ToastDialog intentData={pageConst} updateState={updateToastDialogState} />
        <LoadingDialog intentData={pageConst}/>

        <div className="w-100 col-view">
          <MainHeader intentData={mainHeader}/>

          <div className="col-view min-h-100vh resp-width margin-0-auto">
            
            <div className={`pd-0-15 col-view mg-t-15 pd-b-100 ${pageConst.totalItems==0 ? 'hide-v' : ''}`}>
              <h3>My Orders ({pageConst.totalItems} item)</h3>

              <div className="orderItemsContainer w-100 mg-t-55">

                <div className="orderItemsWrapper col-view">
                  {pageConst.productList}
                </div>

              </div>
            </div>

            <div className={`col-view v-center mg-t-55 ${pageConst.totalItems == 0 ? '' : 'hide-v'}`}>
              <img className="h-180-p" src="https://images.bewakoof.com/images/doodles/empty-cart-page-doodle.png" />
              <label className="ft-sz-18 ft-wgt-600 mg-t-15">Nothing in tha bag</label>
              <Link className="txt-deco-n ft-sz-16 cl-primary pd-10-15 mg-t-5" to={"/"}>Continue Shopping</Link>
            </div>

          </div>

        </div>
        
      </div>
    </div>
  );
}

export default AllOrders;