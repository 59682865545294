import React from 'react';
import { Link } from 'react-router-dom';
import { getHostName,openNewPage } from '../modals/Constants';
import ImageLoader from "../other-components/ImageLoader";
import { Splide, SplideSlide } from '@splidejs/react-splide';
import "@splidejs/react-splide/css";

function ContainerV5Modal({dataList}) {
  return (
    <Splide
      options={ {
        type: 'loop',
        autoplay: true,
        perPage: 1,
        pagination: false,
        arrows: false,
        rewind: true,
      } }
    >
      {dataList.map((data, index) =>(
        <SplideSlide>
          <Link key={index} className='itemContainer col-view ovf-hidden bg-white' target='_blank' to={data['container_item_slug']}>
            <div className='imageContainer'>
              <ImageLoader className='w-100 h-100 objectFitContain lazy-loading br-5' src={data['container_item_image']} alt='containerv5-image' />
            </div>
          </Link>
        </SplideSlide>
      ))}
    </Splide>
  );
}

export default ContainerV5Modal;