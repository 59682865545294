import React from 'react';
import { Link } from 'react-router-dom';
import ImageLoader from "../other-components/ImageLoader";

function ContainerV6Modal({dataList}) {

  return (
    <>
    {dataList!=null ? dataList.map((data, index) => (
      <Link key={index} className='containerItem ovf-hidden bg-white' to={'customize/'+data['container_item_slug']}>
        <div className='containerItemImageHolder'>
          <ImageLoader className='w-100 objectFitContain lazy-loading br-5' src={data['container_item_image']} alt='containerv6-image' />
        </div>
        
        <div className='col-view'>
          <h3 className='ft-wgt-600'>{data['container_item_title']}</h3>
          <p className='ft-sz-14 mg-t-15' dangerouslySetInnerHTML={ { __html: data['container_item_description'] } }></p>
        </div>
      </Link>
    )) : ''}
    </>
  );
}

export default ContainerV6Modal;