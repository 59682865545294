import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import ToastDialog from "../dialogs/ToastDialog";
import MainHeader from "../other-components/MainHeader";
import { API_ACCESS_URL } from "../modals/Constants";
import Footer from "../other-components/Footer";
import ContainerV1Modal from "../other-components/ContainerV1Modal";
import LoadingDialog from "../dialogs/LoadingDialog";
import general from "../css/General.module.css";

function Search() {
  let { urlParam } = useParams();
  const navigate = useNavigate();

  const [pageNumber, setPageNumber] = useState(1);
  const [prevSearchParams, setPrevSearchParams] = useState("");
  const [isDataLoaded, setIsDataLoaded] = useState(false);

  const [pageConst, setConstants] = useState({
    showHeadSearch: true,
    toastMessage: "",
    toastDialogShow: false,
    isShowFooter: true,
    isLoadingShow: false,
    searchedResults: [],
  });

  const [mainHeader, setMainHeader] = useState({
    showHeader: true,
    headerType: 2,
  });

  const updateLoadingStatus = (data) => {
    setConstants((previousState) => {
      return { ...previousState, isLoadingShow: data };
    });
  };

  const updateToastDialogState = (data, msg) => {
    setConstants((previousState) => {
      return { ...previousState, toastDialogShow: data };
    });

    setConstants((previousState) => {
      return { ...previousState, toastMessage: msg };
    });
  };

  const updateSearchResults = (data) => {
    var newArr = pageConst.searchedResults;
    newArr.push(...data);

    setConstants((previousState) => {
      return { ...previousState, searchedResults: newArr };
    });
  };

  function searchProducts(searched) {
    async function requestAPI(url, formData) {
      try {
        const res = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            route: "route-search-products",
          },
          body: JSON.stringify(formData),
        });

        const data = await res.json();
        updateLoadingStatus(false);

        if (data.status_code == "success") {
          setIsDataLoaded(true);
          updateSearchResults(data.data);
        }
      } catch (error) {
        updateLoadingStatus(false);
      }
    }

    if (!isDataLoaded && searched != "" && searched != "def") {
      updateLoadingStatus(true);
      const formData = { SEARCHED: searched, PAGE_NUM: pageNumber };
      requestAPI(API_ACCESS_URL, formData);
    }
  }

  const loadMoreProduct = () => {
    setIsDataLoaded(false);
    setPageNumber(pageNumber + 1);
  };

  const handleScrollBar = (e) => {};

  const handleSubmit = (e) => {
    if (e.target[0].value != "") {
      navigate("/search/" + e.target[0].value, { replace: false });

      setIsDataLoaded(false);
      setPrevSearchParams(e.target[0].value);
    }
    e.preventDefault();
  };

  useEffect(() => {
    searchProducts(urlParam);
  }, [pageConst.searchedResults, prevSearchParams, pageNumber]);

  return (
    <div className="v-center app-bg">
      <div
        className="h-100vh w-100 ovf-scrl-y"
        onScroll={(e) => handleScrollBar(e)}
      >
        <ToastDialog
          intentData={pageConst}
          updateState={updateToastDialogState}
        />
        <LoadingDialog intentData={pageConst} />

        <div className="w-100 col-view a-center">
          <MainHeader intentData={mainHeader} />

          <div className="col-view min-h-100vh resp-width margin-0-auto">
            <form className="pd-15 mg-t-15" onSubmit={(e) => handleSubmit(e)}>
              <div className="w-100 h-55-p row-view pd-10 br-5 bg-l-grey">
                <div className="row-view pd-5 bg-transparent">
                  <i className="bx bx-search ft-sz-18"></i>
                </div>

                <input
                  type="text"
                  className="w-100 inp-box ft-sz-16 inp-ph-color-l-white bg-transparent"
                  placeholder="Search by product, category or collection"
                  autoComplete="off"
                ></input>
              </div>
            </form>
            <h1
              className={`pd-15 ft-sz-20 ft-wgt-500 ${
                urlParam != "def" ? "" : "hide-v"
              }`}
            >
              Search Result For: {urlParam}
            </h1>
            <div className={`w-100 container-v-1`}>
              <div
                className={`w-100 container-v-1-holder pd-10 mg-t-10 mg-b-50`}
              >
                <ContainerV1Modal dataList={pageConst.searchedResults} />
              </div>
            </div>

            <div
              className={[
                general.w100,
                general.viewCenter,
                !isDataLoaded ? general.hideView : "",
              ].join(" ")}
            >
              <div
                className={`w-fit-content v-center pd-10-15 mg-20 br-5 bg-black cl-white`}
                onClick={() => loadMoreProduct()}
              >
                <i className="bx bx-loader-alt"></i>
                <span className="mg-l-10">Load More</span>
              </div>
            </div>
          </div>
        </div>

        <Footer intentData={pageConst} />
      </div>
    </div>
  );
}

export default Search;
